/* eslint-disable camelcase */
import React from 'react'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Col, Collapse, Row, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { HeroBasicInfo, PageMetaInfo, PageForm, ContentModuleBasicInfo, SuiteCardBasicInfo } from '~components'
import { Input, MultiSelectDropdown } from '~stories'

const { Panel } = Collapse

const PageBasicInfo = ({
  addContentModule,
  allSuiteLevels,
  addSuitesCardModule,
  deleteContentModule,
  deleteSuitesCardModule,
  handleAddCta,
  handleButtonTextUpdate,
  handleContentModuleContentChange,
  handleContentModuleCTANameChange,
  handleContentModuleCTAUrlChange,
  handleContentModuleHeaderChange,
  handleContentModuleImageUrlUpdate,
  handleCTAHrefChange,
  handleCTATitleChange,
  handleDeleteCta,
  handleFormModuleDescriptionChange,
  handleFormModuleDisclaimerChange,
  handleFormModuleHeaderChange,
  handleFormModuleIdUpdate,
  handleFormModuleMessageChange,
  handleFormModuleSuccessSubmitButtonTextUpdate,
  handleFormModuleTitleChange,
  handleHeroButtonLabelChange,
  handleHeroChatLabelChange,
  handleHeroDescriptionChange,
  handleHeroEmailChange,
  handleAllEventsChange,
  handleSelectedEventsChange,
  handleHeroFormIdChange,
  handleHeroImageUrlUpdate,
  handleHeroPhoneNumberChange,
  handleHeroSubTitleChange,
  handleHeroTitleChange,
  handlePageMetaDescriptionChange,
  handlePageMetaHeadingChange,
  handlePageMetaKeywordsChange,
  handlePageMetaShowSEOChange,
  handlePageMetaTextChange,
  handlePageMetaTitleChange,
  handlePageNameChange,
  handleSlugChange,
  handleSuiteAmenitiesChange,
  handleSuiteCapacityChange,
  handleSuiteCardShortNameChange,
  handleSuiteCardTypeChange,
  handleSuiteCTAUpdate,
  handleSuiteEventIdeasChange,
  handleSuiteEventTypeChange,
  handleSuiteFormStatus,
  handleSuiteHospitalityChange,
  handleSuiteLogoUpload,
  handleSuiteSizeChange,
  handleSuiteSubTitleChange,
  handleSuiteTitleChange,
  page,
  pageTypes,
  selectedType,
  suitesAmenities,
  suitesCapacity,
  suitesEventIdeas,
  suitesEventType,
  suitesHospitality,
  suitesSize
}) => {
  return (
    <Card>
      <Row>
        <Col xl={24} xs={24}>
          <Input label='Name' onChange={handlePageNameChange} value={page?.name} />
        </Col>
        <Col xl={24} xs={24}>
          <Input label='Slug' onChange={handleSlugChange} value={page?.slug} />
        </Col>
        {pageTypes && <Col xl={24} xs={24}>
          <MultiSelectDropdown
            allowClear={true}
            disabled
            displayField='label'
            dropdownOptions={pageTypes}
            label='Type'
            selectedItems={selectedType}
            valueField='value'
          />
        </Col>}
      </Row>
      <Collapse accordion defaultActiveKey={['0']} expandIconPosition='right'>
        <Panel key='1' header='Hero' style={{
          fontSize: 20
        }}>
          <Card style={{ borderColor: '#3642b1' }}>
            <HeroBasicInfo
              handleButtonLabelChange={handleHeroButtonLabelChange}
              handleChatLabelChange={handleHeroChatLabelChange}
              handleContentChange={handleHeroDescriptionChange}
              handleEmailChange={handleHeroEmailChange}
              handleFormIdChange={handleHeroFormIdChange}
              handleImageUrlUpdate={handleHeroImageUrlUpdate}
              handlePhoneNumberChange={handleHeroPhoneNumberChange}
              handleSubTitleChange={handleHeroSubTitleChange}
              handleTitleChange={handleHeroTitleChange}
              hero={page?.hero || {}}
            />
          </Card>
        </Panel>
        <Panel key='2'
          extra={
            <div onClick={addContentModule}><Tooltip title='Add new content module'>
              <Button icon={<PlusOutlined />} />
            </Tooltip>
            </div>
          }
          header='Content Modules'
          style={{
            fontSize: 20
          }}
        >
          {page?.content_modules && <Card style={{ borderColor: '#3f6600' }}><Collapse accordion defaultActiveKey={['0']} expandIconPosition='right'>
            {
              page?.content_modules.map((contentModule, index) => <Panel
                key={index}
                extra={
                  <div onClick={(event) => deleteContentModule(event, index)}><Tooltip title='Delete module'>
                    <Button danger icon={<DeleteFilled />} />
                  </Tooltip>
                  </div>
                }
                header={contentModule?.header}
                style={{
                  fontSize: 20
                }}
              >
                <ContentModuleBasicInfo
                  key={index}
                  contentIndex={index}
                  contentModule={contentModule}
                  handleCTANameChange={handleContentModuleCTANameChange}
                  handleCTAUrlChange={handleContentModuleCTAUrlChange}
                  handleContentChange={handleContentModuleContentChange}
                  handleHeaderChange={handleContentModuleHeaderChange}
                  handleImageUrlUpdate={handleContentModuleImageUrlUpdate}
                />
              </Panel>
              )
            }</Collapse>
          </Card>}
        </Panel>
        <Panel key='3' header='Page Meta' style={{
          fontSize: 20
        }}>
          <Card style={{ borderColor: '#3642b1' }}>
            <PageMetaInfo
              handleDescriptionChange={handlePageMetaDescriptionChange}
              handleHeadingChange={handlePageMetaHeadingChange}
              handleKeywordsChange={handlePageMetaKeywordsChange}
              handleShowSEOChange={handlePageMetaShowSEOChange}
              handleTextChange={handlePageMetaTextChange}
              handleTitleChange={handlePageMetaTitleChange}
              pageMeta={page?.page_meta || {}}
            />
          </Card>
        </Panel>
        <Panel key='4' header='Form' style={{
          fontSize: 20
        }}>
          <Card>
            <PageForm
              form={page?.form || {}}
              handleButtonTextUpdate={handleButtonTextUpdate}
              handleDescriptionChange={handleFormModuleDescriptionChange}
              handleDisclaimerChange={handleFormModuleDisclaimerChange}
              handleFormIdUpdate={handleFormModuleIdUpdate}
              handleHeaderChange={handleFormModuleHeaderChange}
              handleMessageChange={handleFormModuleMessageChange}
              handleSuccessSubmitButtonUpdate={handleFormModuleSuccessSubmitButtonTextUpdate}
              handleTitleChange={handleFormModuleTitleChange}
            />
          </Card>
        </Panel>
        <Panel key='5'
          extra={
            <div onClick={addSuitesCardModule}><Tooltip title='Add new card'>
              <Button icon={<PlusOutlined />} />
            </Tooltip>
            </div>
          }
          header='Cards'
          style={{
            fontSize: 20
          }}>
          {page?.cards && <Card style={{ borderColor: '#d46b08' }}><Collapse accordion defaultActiveKey={['0']} expandIconPosition='right'>
            {
              page?.cards.map((suiteCard, suiteIndex) => <Panel key={suiteIndex}
                extra={
                  <div onClick={(event) => deleteSuitesCardModule(event, suiteIndex)}><Tooltip title='Delete card'>
                    <Button danger icon={<DeleteFilled />} />
                  </Tooltip>
                  </div>
                }
                header={suiteCard?.title}
                style={{
                  fontSize: 20
                }}
              >
                <SuiteCardBasicInfo
                  key={suiteIndex}
                  allSuiteLevels={allSuiteLevels}
                  handleAddCta={handleAddCta}
                  handleAllEventsChange={handleAllEventsChange}
                  handleAmenitiesChange={handleSuiteAmenitiesChange}
                  handleCTAHrefChange={handleCTAHrefChange}
                  handleCTATitleChange={handleCTATitleChange}
                  handleCTAUpdate={handleSuiteCTAUpdate}
                  handleCapacityChange={handleSuiteCapacityChange}
                  handleDeleteCta={handleDeleteCta}
                  handleEventIdeasChange={handleSuiteEventIdeasChange}
                  handleEventTypeChange={handleSuiteEventTypeChange}
                  handleFormStatus={handleSuiteFormStatus}
                  handleHospitalityChange={handleSuiteHospitalityChange}
                  handleImageUrlUpdate={handleSuiteLogoUpload}
                  handleSelectedEventsChange={handleSelectedEventsChange}
                  handleSizeChange={handleSuiteSizeChange}
                  handleSubTitleChange={handleSuiteSubTitleChange}
                  handleSuiteCardShortNameChange={handleSuiteCardShortNameChange}
                  handleSuiteCardTypeChange={handleSuiteCardTypeChange}
                  handleTitleChange={handleSuiteTitleChange}
                  suiteCard={suiteCard}
                  suiteIndex={suiteIndex}
                  suitesAmenities={suitesAmenities}
                  suitesCapacity={suitesCapacity}
                  suitesEventIdeas={suitesEventIdeas}
                  suitesEventType={suitesEventType}
                  suitesHospitality={suitesHospitality}
                  suitesSize={suitesSize}
                />
              </Panel>
              )
            }</Collapse>
          </Card>}
        </Panel>
      </Collapse>
    </Card>
  )
}

PageBasicInfo.propTypes = {
  addContentModule: PropTypes.func.isRequired,
  addSuitesCardModule: PropTypes.func.isRequired,
  allELSEvents: PropTypes.array,
  allEvents: PropTypes.func.isRequired,
  allIsLEvents: PropTypes.array,
  allLSLEvents: PropTypes.array,
  allSuiteLevels: PropTypes.array.isRequired,
  deleteContentModule: PropTypes.func.isRequired,
  deleteSuitesCardModule: PropTypes.func.isRequired,
  handleAddCta: PropTypes.func.isRequired,
  handleAllEventsChange: PropTypes.func.isRequired,
  handleButtonTextUpdate: PropTypes.func.isRequired,
  handleCTAHrefChange: PropTypes.func.isRequired,
  handleCTATitleChange: PropTypes.func.isRequired,
  handleContentModuleCTANameChange: PropTypes.func.isRequired,
  handleContentModuleCTAUrlChange: PropTypes.func.isRequired,
  handleContentModuleContentChange: PropTypes.func.isRequired,
  handleContentModuleHeaderChange: PropTypes.func.isRequired,
  handleContentModuleImageUrlUpdate: PropTypes.func.isRequired,
  handleDeleteCta: PropTypes.func.isRequired,
  handleFormModuleDescriptionChange: PropTypes.func.isRequired,
  handleFormModuleDisclaimerChange: PropTypes.func.isRequired,
  handleFormModuleHeaderChange: PropTypes.func.isRequired,
  handleFormModuleIdUpdate: PropTypes.func.isRequired,
  handleFormModuleMessageChange: PropTypes.func.isRequired,
  handleFormModuleSuccessSubmitButtonTextUpdate: PropTypes.func.isRequired,
  handleFormModuleTitleChange: PropTypes.func.isRequired,
  handleHeroButtonLabelChange: PropTypes.func.isRequired,
  handleHeroChatLabelChange: PropTypes.func.isRequired,
  handleHeroContentChange: PropTypes.func.isRequired,
  handleHeroDescriptionChange: PropTypes.func.isRequired,
  handleHeroEmailChange: PropTypes.func.isRequired,
  handleHeroFormIdChange: PropTypes.func.isRequired,
  handleHeroImageUrlUpdate: PropTypes.func.isRequired,
  handleHeroPhoneNumberChange: PropTypes.func.isRequired,
  handleHeroSubTitleChange: PropTypes.func.isRequired,
  handleHeroTitleChange: PropTypes.func.isRequired,
  handlePageMetaDescriptionChange: PropTypes.func.isRequired,
  handlePageMetaHeadingChange: PropTypes.func.isRequired,
  handlePageMetaKeywordsChange: PropTypes.func.isRequired,
  handlePageMetaShowSEOChange: PropTypes.func.isRequired,
  handlePageMetaTextChange: PropTypes.func.isRequired,
  handlePageMetaTitleChange: PropTypes.func.isRequired,
  handlePageNameChange: PropTypes.func.isRequired,
  handleSelectedEventsChange: PropTypes.func.isRequired,
  handleSlugChange: PropTypes.func.isRequired,
  handleSuiteAmenitiesChange: PropTypes.func.isRequired,
  handleSuiteCTAUpdate: PropTypes.func.isRequired,
  handleSuiteCapacityChange: PropTypes.func.isRequired,
  handleSuiteCardShortNameChange: PropTypes.func.isRequired,
  handleSuiteCardTypeChange: PropTypes.func.isRequired,
  handleSuiteEventIdeasChange: PropTypes.func.isRequired,
  handleSuiteEventTypeChange: PropTypes.func.isRequired,
  handleSuiteFormStatus: PropTypes.func.isRequired,
  handleSuiteHospitalityChange: PropTypes.func.isRequired,
  handleSuiteLogoUpload: PropTypes.func.isRequired,
  handleSuiteSizeChange: PropTypes.func.isRequired,
  handleSuiteSubTitleChange: PropTypes.func.isRequired,
  handleSuiteTitleChange: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  pageTypes: PropTypes.array,
  promotedEvents: PropTypes.array,
  selectedELSEvents: PropTypes.array,
  selectedIsLEvents: PropTypes.array,
  selectedLSLEvents: PropTypes.array,
  selectedType: PropTypes.object,
  setAllELSEvents: PropTypes.func,
  setAllEvents: PropTypes.func.isRequired,
  setAllIsLEvents: PropTypes.func,
  setAllLSLEvents: PropTypes.func,
  setPromotedEvents: PropTypes.func.isRequired,
  setSelectedELSEvents: PropTypes.func,
  setSelectedIsLEvents: PropTypes.func,
  setSelectedLSLEvents: PropTypes.func,
  suitesAmenities: PropTypes.array.isRequired,
  suitesCapacity: PropTypes.array.isRequired,
  suitesEventIdeas: PropTypes.array.isRequired,
  suitesEventType: PropTypes.array.isRequired,
  suitesHospitality: PropTypes.array.isRequired,
  suitesSize: PropTypes.array.isRequired
}

PageBasicInfo.defaultProps = {
  page: {}
}

export default PageBasicInfo
