import React from 'react'
import { Card, Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { Input, RichTextInput } from '~stories'

const PageForm = ({
  handleFormIdUpdate,
  handleDescriptionChange,
  handleHeaderChange,
  handleDisclaimerChange,
  handleSuccessSubmitButtonUpdate,
  handleTitleChange,
  handleMessageChange,
  handleButtonTextUpdate,
  form
}) => {
  const {
    submit_button_text: formBtnText,
    description,
    disclaimer,
    formstack_id: formId,
    header,
    success_screen: {
      title,
      message,
      button_text: btnText
    }
  } = form

  return (
    <>
      <Row gutter={8}>
        <Col span={5}>
          <Input label={'Formstack Id'}
            onChange={handleFormIdUpdate}
            value={formId}
          />
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={8}>
          <Input label={'Submit Button Text'}
            onChange={handleButtonTextUpdate}
            value={formBtnText}
          />
        </Col>
      </Row>

      <RichTextInput label='Header' onChange={handleHeaderChange} value={header}/>
      <RichTextInput label='Description' onChange={handleDescriptionChange} value={description}/>
      <RichTextInput label='Disclaimer' onChange={handleDisclaimerChange} value={disclaimer}/>
      < br/>
      <Card title="Success Screen">
        <RichTextInput label='Title' onChange={handleTitleChange} value={title}/>
        <RichTextInput label='Message' onChange={handleMessageChange} value={message}/>
        <Input label={'Button Text'}
          onChange={handleSuccessSubmitButtonUpdate}
          value={btnText}
        />
      </Card>
    </>
  )
}

PageForm.propTypes = {
  form: PropTypes.object.isRequired,
  handleButtonTextUpdate: PropTypes.func,
  handleCoundDownEndDate: PropTypes.func,
  handleDescriptionChange: PropTypes.func.isRequired,
  handleDisclaimerChange: PropTypes.func,
  handleFormIdUpdate: PropTypes.func,
  handleHeaderChange: PropTypes.func.isRequired,
  handleImageUpload: PropTypes.func,
  handleMessageChange: PropTypes.func,
  handleSuccessSubmitButtonUpdate: PropTypes.func,
  handleTitleChange: PropTypes.func
}

PageForm.defaultProps = {
  form: {}
}

export default PageForm
