const {
  REACT_APP_BASE_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_COGNITO_DOMAIN,
  REACT_APP_DOMAIN_NAME
} = process.env

export const adminEndpoint = {
  tmImport: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/admin/import`
  },
  upload: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/admin/sample_data`
  }
}

export const uploadEndpoint = {
  presignedUrl: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/generate-presigned-url`
  }
}

export const marqueeEndpoint = {
  upload: employeeId => ({
    method: 'PUT',
    url: `${REACT_APP_BASE_URL}/marquee/upload/${employeeId}`
  })
}

export const contentEndpoint = {
  update: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/admin/content`
  },
  list: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/admin/content`
  }
}

export const dashboardEndpoint = {
  stats: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/dashboard`
  }
}

export const artistEndpoint = {
  edit: artistId => ({
    url: `${REACT_APP_BASE_URL}/artists/${artistId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/artists/list`
  },
  update: artistId => ({
    url: `${REACT_APP_BASE_URL}/artists/${artistId}`,
    method: 'PUT'
  })
}

export const cognitoAuthEndpoint = {
  logout: {
    method: 'GET',
    url: `${REACT_APP_COGNITO_DOMAIN}/logout`
  },
  signIn: {
    method: 'POST',
    url: `${REACT_APP_COGNITO_DOMAIN}/login?client_id=${REACT_APP_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${REACT_APP_DOMAIN_NAME}/authorization`
  },
  token: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/authorization`
  },
  userInfo: {
    method: 'GET',
    url: `${REACT_APP_COGNITO_DOMAIN}/oauth2/userInfo`
  }
}

export const eventsEndpoint = {
  byId: eventId => ({
    url: `${REACT_APP_BASE_URL}/events/${eventId}/edit`,
    method: 'GET'
  }),
  delete: eventId => ({
    url: `${REACT_APP_BASE_URL}/events/${eventId}`,
    method: 'DELETE'
  }),
  filter: {
    url: `${REACT_APP_BASE_URL}/events/filter`,
    method: 'POST'
  },
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/events/list`
  },
  update: eventId => ({
    url: `${REACT_APP_BASE_URL}/events/${eventId}`,
    method: 'PUT'
  })
}

export const organizationEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/organizations`
  },
  edit: organisationId => ({
    url: `${REACT_APP_BASE_URL}/organizations/${organisationId}/edit`,
    method: 'GET'
  }),
  list: {
    url: `${REACT_APP_BASE_URL}/organizations/list`,
    method: 'POST'
  },
  update: organisationId => ({
    url: `${REACT_APP_BASE_URL}/organizations/${organisationId}`,
    method: 'PUT'
  }),
  delete: organisationId => ({
    url: `${REACT_APP_BASE_URL}/organizations/${organisationId}`,
    method: 'DELETE'
  })
}

export const tmImportLogsEndpoint = {
  list: {
    url: `${REACT_APP_BASE_URL}/tm_import_logs/list`,
    method: 'POST'
  }
}

export const priceTemplatesEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/price_templates`
  },
  copy: priceTemplateId => ({
    url: `${REACT_APP_BASE_URL}/price_templates/${priceTemplateId}/copy`,
    method: 'GET'
  }),
  edit: priceTemplateId => ({
    url: `${REACT_APP_BASE_URL}/price_templates/${priceTemplateId}/edit`,
    method: 'GET'
  }),
  eventsById: priceTemplateId => ({
    url: `${REACT_APP_BASE_URL}/price_templates/${priceTemplateId}/events`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/price_templates/list`
  },
  index: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/price_templates`
  },
  new: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/price_templates/new`
  },
  update: priceTemplateId => ({
    url: `${REACT_APP_BASE_URL}/price_templates/${priceTemplateId}`,
    method: 'PUT'
  })
}

export const promocodesEndpoint = {
  edit: promocodeId => ({
    url: `${REACT_APP_BASE_URL}/promo_codes/${promocodeId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/promo_codes/list`
  },
  update: promocodeId => ({
    url: `${REACT_APP_BASE_URL}/promo_codes/${promocodeId}`,
    method: 'PUT'
  }),
  add: {
    url: `${REACT_APP_BASE_URL}/promo_codes`,
    method: 'POST'
  },
  copy: promocodeId => ({
    url: `${REACT_APP_BASE_URL}/promo_codes/${promocodeId}/copy`,
    method: 'GET'
  })
}

export const publishingPreferencesEndpoint = {
  edit: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/publishing_preferences`
  },
  update: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/publishing_preferences`
  }
}

export const cfInvalidationEndpoint = {
  invalidate: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/cf_invalidate`
  }
}

export const seriesEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/series`
  },
  byId: seriesId => ({
    url: `${REACT_APP_BASE_URL}/series/${seriesId}`,
    method: 'GET'
  }),
  edit: seriesId => ({
    url: `${REACT_APP_BASE_URL}/series/${seriesId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/series/list`
  },
  index: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/series`
  },
  new: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/series/new`
  },
  update: seriesId => ({
    url: `${REACT_APP_BASE_URL}/series/${seriesId}`,
    method: 'PUT'
  })
}

export const sponsorEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/sponsors`
  },
  edit: sponsorId => ({
    url: `${REACT_APP_BASE_URL}/sponsors/${sponsorId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/sponsors/list`
  },
  new: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/sponsors/new`
  },
  update: sponsorId => ({
    url: `${REACT_APP_BASE_URL}/sponsors/${sponsorId}`,
    method: 'PUT'
  })
}

export const optInModalEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/opt-in-modal`
  },
  edit: optInId => ({
    url: `${REACT_APP_BASE_URL}/opt-in-modal/${optInId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/opt-in-modal/list`
  },
  new: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/opt-in-modal/new`
  },
  update: optInId => ({
    url: `${REACT_APP_BASE_URL}/opt-in-modal/${optInId}`,
    method: 'PUT'
  })
}

export const flagEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/flags`
  },
  edit: flagId => ({
    url: `${REACT_APP_BASE_URL}/flags/${flagId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/flags/list`
  },
  new: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/flags/new`
  },
  update: flagId => ({
    url: `${REACT_APP_BASE_URL}/flags/${flagId}`,
    method: 'PUT'
  })
}

export const heroEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/hero-modules`
  },
  edit: heroId => ({
    url: `${REACT_APP_BASE_URL}/hero-modules/${heroId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/hero-modules/list`
  },
  new: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/hero-modules/new`
  },
  delete: heroId => ({
    url: `${REACT_APP_BASE_URL}/hero-modules/${heroId}`,
    method: 'DELETE'
  }),
  update: heroId => ({
    url: `${REACT_APP_BASE_URL}/hero-modules/${heroId}`,
    method: 'PUT'
  })
}

export const pageEndpoint = {
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/pages/list`
  },
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/pages`
  },
  edit: pageId => ({
    url: `${REACT_APP_BASE_URL}/pages/${pageId}/edit`,
    method: 'GET'
  }),
  delete: pageId => ({
    url: `${REACT_APP_BASE_URL}/pages/${pageId}`,
    method: 'DELETE'
  }),
  update: pageId => ({
    url: `${REACT_APP_BASE_URL}/pages/${pageId}`,
    method: 'PUT'
  })
}

export const contentModulesEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/content-modules`
  },
  edit: contentId => ({
    url: `${REACT_APP_BASE_URL}/content-modules/${contentId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/content-modules/list`
  },
  new: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/content-modules/new`
  },
  delete: contentId => ({
    url: `${REACT_APP_BASE_URL}/content-modules/${contentId}`,
    method: 'DELETE'
  }),
  update: contentId => ({
    url: `${REACT_APP_BASE_URL}/content-modules/${contentId}`,
    method: 'PUT'
  })
}

export const suiteCardsEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/suite-cards`
  },
  edit: contentId => ({
    url: `${REACT_APP_BASE_URL}/suite-cards/${contentId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/suite-cards/list`
  },
  new: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/suite-cards/new`
  },
  delete: contentId => ({
    url: `${REACT_APP_BASE_URL}/suite-cards/${contentId}`,
    method: 'DELETE'
  }),
  update: contentId => ({
    url: `${REACT_APP_BASE_URL}/suite-cards/${contentId}`,
    method: 'PUT'
  })
}

export const venuesEndpoint = {
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/venues`
  },
  edit: venueId => ({
    url: `${REACT_APP_BASE_URL}/venues/${venueId}/edit`,
    method: 'GET'
  }),
  list: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/venues/list`
  },
  update: venueId => ({
    url: `${REACT_APP_BASE_URL}/venues/${venueId}`,
    method: 'PUT'
  })
}

export const navigationEndpoint = {
  list: {
    method: 'GET',
    url: `${REACT_APP_BASE_URL}/navigations`
  },
  add: {
    method: 'POST',
    url: `${REACT_APP_BASE_URL}/navigations`
  },
  update: navId => ({
    url: `${REACT_APP_BASE_URL}/navigations/${navId}`,
    method: 'PUT'
  })
}
