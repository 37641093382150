import {
  ADD_NEW_PAGE,
  FETCH_PAGE_CONTENTS,
  PAGE_UPDATE_STATUS,
  STORE_PAGE_TO_EDIT,
  REMOVE_PAGE_BY_ID
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_PAGE:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_PAGE_CONTENTS:
      return {
        ...state,
        ...action.payload
      }
    case PAGE_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case STORE_PAGE_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_PAGE_BY_ID:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
