import 'isomorphic-fetch'

import { suiteCardsEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'
import {
  ADD_NEW_SUITE_CARD_MODULE,
  SUITE_CARD_MODULE_UPDATE_STATUS,
  FETCH_SUITE_CARD_MODULES,
  REMOVE_SUITE_CARD_MODULE_BY_ID,
  STORE_SUITE_CARD_MODULE_TO_EDIT,
  NEW_SUITE_CARD_MODULE
} from '../actionTypes'

export const resetSuiteCardsActionStatus = () => async dispatch => {
  dispatch({
    type: ADD_NEW_SUITE_CARD_MODULE,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}

export const resetUpdateSuiteCardContentStatus = () => async dispatch => {
  dispatch({
    type: SUITE_CARD_MODULE_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}

export const addSuiteCardModule = (suiteCard, accessToken) => async dispatch => {
  try {
    const { method, url } = suiteCardsEndpoint.add
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify({ suiteCard }),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { addedSuiteCard: { _id: newSuiteCardId }, message, success } = response
        dispatch({
          type: ADD_NEW_SUITE_CARD_MODULE,
          payload: { newSuiteCardId, message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const newSuiteCardModule = (accessToken) => async dispatch => {
  try {
    const { method, url } = suiteCardsEndpoint.new
    const idToken = getIdToken()
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const {
          allEvents,
          suitesAmenities,
          suitesCapacity,
          suitesEventIdeas,
          suitesHospitality,
          suitesSize,
          suitesEventType,
          allSuiteLevels
        } = response
        dispatch({
          type: NEW_SUITE_CARD_MODULE,
          payload: {
            allEvents,
            allSuiteLevels,
            suitesAmenities,
            suitesCapacity,
            suitesEventIdeas,
            suitesEventType,
            suitesHospitality,
            suitesSize
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const removeSuiteCardModuleById = (suiteCardId, accessToken) => async dispatch => {
  const { method, url } = suiteCardsEndpoint.delete(suiteCardId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    }
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message: deleteMessage, success: deleteStatus } = apiResponse

  dispatch({
    type: REMOVE_SUITE_CARD_MODULE_BY_ID,
    payload: {
      deleteMessage,
      deleteStatus
    }
  })
}

export const fetchSuiteCardsList = params => async dispatch => {
  const { method, url } = suiteCardsEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { suiteCards: rows, suiteCardsCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_SUITE_CARD_MODULES,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchSuiteCardModuleById = objectId => async dispatch => {
  try {
    const { method, url } = suiteCardsEndpoint.edit(objectId)
    const idToken = getIdToken()
    const apiResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .catch(error => console.error(error))
    dispatch({
      type: STORE_SUITE_CARD_MODULE_TO_EDIT,
      payload: {
        ...apiResponse
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const updateSuiteCardModule = (objectId, suiteCard, accessToken) => async dispatch => {
  try {
    const { method, url } = suiteCardsEndpoint.update(objectId)
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify(suiteCard),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { message, success } = response
        dispatch({
          type: SUITE_CARD_MODULE_UPDATE_STATUS,
          payload: { message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}
