/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Card } from 'antd'
import PropTypes from 'prop-types'

import { Input } from '~stories'

const CardCTA = ({
  cardCTA,
  suiteCTAs,
  ctaIndex,
  handleDeleteCta,
  handleCTATitleUpdate,
  handleCTAHrefUpdate,
  suiteIndex
}) => {
  const [title, setTitle] = useState()
  const [href, setHref] = useState()

  useEffect(() => {
    if (cardCTA) {
      setTitle(cardCTA.title)
      setHref(cardCTA.href)
    }
  }, [cardCTA])
  const handleCTATitleChange = (title) => {
    setTitle(title)
  }
  const handleCTAHrefChange = (href) => {
    setHref(href)
  }

  return (
    <Card
      extra={[suiteCTAs && suiteCTAs?.length > 1 && <Button danger icon={<DeleteOutlined />} onClick={(event) => handleDeleteCta(event, ctaIndex, suiteIndex)} shape="circle" style={{ marginLeft: 12 }} />]}
      style={{ width: '100%', marginTop: 8 }}
    >
      <Input label='Title' maxLength={70} onBlur={(event) => handleCTATitleUpdate(event.target.value, ctaIndex)} onChange={(event) => handleCTATitleChange(event.target.value)} value={title} />
      <Input label='Link' maxLength={155} onBlur={(event) => handleCTAHrefUpdate(event.target.value, ctaIndex)} onChange={(event) => handleCTAHrefChange(event.target.value)} value={href}/>
    </Card>
  )
}

CardCTA.propTypes = {
  cardCTA: PropTypes.object.isRequired,
  ctaIndex: PropTypes.number.isRequired,
  handleCTAHrefUpdate: PropTypes.func.isRequired,
  handleCTATitleUpdate: PropTypes.func.isRequired,
  handleDeleteCta: PropTypes.func.isRequired,
  suiteCTAs: PropTypes.array.isRequired,
  suiteIndex: PropTypes.number.isRequired
}

CardCTA.defaultProps = {
  suiteCard: {}
}

export default CardCTA
