/* eslint-disable camelcase */
import React from 'react'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { Checkbox, RichTextInput, Input } from '~stories'

const PageMetaInfo = ({
  handleDescriptionChange,
  handleHeadingChange,
  handleKeywordsChange,
  handleTextChange,
  handleTitleChange,
  handleShowSEOChange,
  pageMeta
}) => {
  const {
    title,
    description,
    keywords,
    should_show_seo: showSEO,
    heading,
    text
  } = pageMeta
  return (
    <>
      <Input label='Title' onChange={handleTitleChange} value={title} />
      <RichTextInput label='Description' onChange={handleDescriptionChange} value={description} />
      <Input label='Heading' onChange={handleHeadingChange} value={heading} />
      <RichTextInput label='Text' onChange={handleTextChange} value={text} />
      <Row gutter={16}>
        <Col span={6}>
          <Checkbox
            checked={showSEO}
            label='Show SEO'
            onChange={handleShowSEOChange}
          /></Col>
        <Col span={6}>
          <Input label='Keywords' onChange={handleKeywordsChange} value={keywords} />
        </Col>
      </Row>
    </>
  )
}

PageMetaInfo.propTypes = {
  handleDescriptionChange: PropTypes.func,
  handleHeadingChange: PropTypes.func,
  handleKeywordsChange: PropTypes.func,
  handleShowSEOChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleTitleChange: PropTypes.func,
  pageMeta: PropTypes.object.isRequired
}

PageMetaInfo.defaultProps = {
  pageMeta: {}
}

export default PageMetaInfo
