import 'isomorphic-fetch'

import { contentModulesEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'
import {
  ADD_NEW_CONTENT_MODULE,
  CONTENT_MODULE_UPDATE_STATUS,
  FETCH_CONTENT_MODULES,
  REMOVE_CONTENT_MODULE_BY_ID,
  STORE_CONTENT_MODULE_TO_EDIT
} from '../actionTypes'

export const resetContentsActionStatus = () => async dispatch => {
  dispatch({
    type: ADD_NEW_CONTENT_MODULE,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}

export const resetUpdateContentStatus = () => async dispatch => {
  dispatch({
    type: CONTENT_MODULE_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}

export const addContentModule = (contentModule, accessToken) => async dispatch => {
  try {
    const { method, url } = contentModulesEndpoint.add
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify({ contentModule }),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { addedContentModule: { _id: newContentModuleId }, message, success } = response
        dispatch({
          type: ADD_NEW_CONTENT_MODULE,
          payload: { newContentModuleId, message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const removeContentModuleById = (contentModuleId, accessToken) => async dispatch => {
  const { method, url } = contentModulesEndpoint.delete(contentModuleId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    }
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message: deleteMessage, success: deleteStatus } = apiResponse

  dispatch({
    type: REMOVE_CONTENT_MODULE_BY_ID,
    payload: {
      deleteMessage,
      deleteStatus
    }
  })
}

export const fetchContentModulesList = params => async dispatch => {
  const { method, url } = contentModulesEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { contentModules: rows, contentModulesCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_CONTENT_MODULES,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchContentModuleById = objectId => async dispatch => {
  try {
    const { method, url } = contentModulesEndpoint.edit(objectId)
    const idToken = getIdToken()
    const apiResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .catch(error => console.error(error))
    dispatch({
      type: STORE_CONTENT_MODULE_TO_EDIT,
      payload: {
        ...apiResponse
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const updateContentModule = (objectId, contentModule, accessToken) => async dispatch => {
  try {
    const { method, url } = contentModulesEndpoint.update(objectId)
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify(contentModule),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { message, success } = response
        dispatch({
          type: CONTENT_MODULE_UPDATE_STATUS,
          payload: { message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}
