/* eslint-disable camelcase */
import React from 'react'
import { Col, Row, Form } from 'antd'
import PropTypes from 'prop-types'

import InputMask from 'react-input-mask'
import { LogoUploader } from '~components'
import { Label, RichTextInput, Input } from '~stories'
const { Item: AntDFormItem } = Form

const HeroBasicInfo = ({
  hero,
  handleButtonLabelChange,
  handlePhoneNumberChange,
  handleContentChange,
  handleSubTitleChange,
  handleImageUrlUpdate,
  handleChatLabelChange,
  handleEmailChange,
  handleTitleChange
}) => {
  const {
    button_name: buttonName,
    chat_button: chatButton,
    description,
    email,
    phone_number: phoneNumber,
    sub_title: subTitle,
    title,
    image
  } = hero
  return (
    <>
      <Input label='Title' maxLength={70} onChange={handleTitleChange} value={title} />
      <RichTextInput label='Description' onChange={handleContentChange} value={description}/>
      <Input label='Sub Title' maxLength={155} onChange={handleSubTitleChange} value={subTitle}/>
      <Row gutter={16}>
        <Col span={24}><Input label='Email' onChange={handleEmailChange} type='email' value={email}/></Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}><Input label='Button Label' onChange={handleButtonLabelChange} value={buttonName}/></Col>
        <Col span={8}><AntDFormItem>
          <div style={{
            padding: '5px 10px 5px 0px',
            fontSize: 14,
            fontWeight: 'normal'
          }}>Phone Number</div>
          <InputMask mask="+9 (999) 999-9999"
            onChange={handlePhoneNumberChange}
            style={{
              width: '100%',
              padding: '4px 11px',
              color: 'rgba(0, 0, 0, .85)',
              fontSize: '14px',
              lineHeight: '1.5715',
              backgroundColor: '#fff',
              border: '1px solid #d9d9d9',
              borderRadius: '2px'
            }}
            value={phoneNumber} />
        </AntDFormItem></Col>
        <Col span={8}><Input label='Chat Label' onChange={handleChatLabelChange} value={chatButton}/></Col>
      </Row>
      <Row gutter={16}>
        <Col span={18}>
          <Label value='Background Image' />
          <div>
            <LogoUploader handleUpload={(thumbnailImageUrl, secureImageURL) => {
              const imageUrl = secureImageURL || thumbnailImageUrl
              handleImageUrlUpdate(imageUrl)
            }} logoUrl={image?.src} />
          </div>
        </Col>
      </Row>
    </>
  )
}

HeroBasicInfo.propTypes = {
  handleButtonLabelChange: PropTypes.func,
  handleChatLabelChange: PropTypes.func,
  handleContentChange: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func,
  handleImageUrlUpdate: PropTypes.func,
  handleLogoUpload: PropTypes.func,
  handlePhoneNumberChange: PropTypes.func,
  handleShowIconChange: PropTypes.func,
  handleSubTitleChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func,
  hero: PropTypes.object.isRequired
}

HeroBasicInfo.defaultProps = {
  hero: {}
}

export default HeroBasicInfo
