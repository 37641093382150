const VALIDATION_ERRORS = {
  INVALID_DESCRIPTION: 'Provide valid description.',
  INVALID_ICON_URL: 'Provide valid icon url.',
  INVALID_CTA: 'Provide valid cta attributes.',
  INVALID_TITLE: 'Provide valid title'
}

const isValidTitle = title => title.length > 0

const isValidObject = (cta) => {
  if (!cta || typeof cta !== 'object') {
    return false
  }

  if (!cta.name || typeof cta.name !== 'string' || !cta.name.trim()) {
    return false
  }

  if (!cta.url || typeof cta.url !== 'string') {
    return false
  }

  return true
}

export const validateContentModule = content => {
  const { cta, description = '', icon = '', header = '' } = content

  if (!isValidTitle(header)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  if (!isValidTitle(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DESCRIPTION }
  }

  if (!isValidObject(cta)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_CTA }
  }

  if (!isValidTitle(icon)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_ICON_URL }
  }

  return { isValid: true, message: '' }
}

export const validateContentModules = contents => {
  const validateResponse = contents.map(content => validateContentModule(content))
  const invalidObject = validateResponse.find(content => !content.isValid)
  if (invalidObject) {
    return invalidObject
  }
  return { isValid: true, message: '' }
}
