const VALIDATION_ERRORS = {
  INVALID_DESCRIPTION: 'Provide valid page meta description.',
  INVALID_KEYWORDS: 'Provide valid page meta keywords.',
  INVALID_ICON_URL: 'Provide valid page meta icon url.',
  INVALID_HEADING: 'Provide valid page meta heading.',
  INVALID_TEXT: 'Provide valid page meta text.',
  INVALID_EMAIL: 'Provide valid page meta email.',
  INVALID_IMAGE: 'Provide valid page meta image.',
  INVALID_CHAT_BUTTON: 'Provide valid page meta chat button.',
  INVALID_FORM: 'Provide valid page meta form id.',
  INVALID_TITLE: 'Provide valid page meta title',
  INVALID_EMAIL_FORMAT: 'Provide valid page meta email address'
}

const isValidTitle = title => title.length > 0

export const validatePageMeta = pageMeta => {
  const {
    title = '',
    description = '',
    keywords = '',
    heading = '',
    text = ''
  } = pageMeta

  if (!isValidTitle(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  if (!isValidTitle(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DESCRIPTION }
  }

  if (!isValidTitle(keywords)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_KEYWORDS }
  }

  if (!isValidTitle(heading)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_HEADING }
  }

  if (!isValidTitle(text)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TEXT }
  }

  return { isValid: true, message: '' }
}
