import {
  ADD_NEW_CONTENT_MODULE,
  CONTENT_MODULE_UPDATE_STATUS,
  FETCH_CONTENT_MODULES,
  REMOVE_CONTENT_MODULE_BY_ID,
  STORE_CONTENT_MODULE_TO_EDIT
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_CONTENT_MODULE:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_CONTENT_MODULES:
      return {
        ...state,
        ...action.payload
      }
    case STORE_CONTENT_MODULE_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case CONTENT_MODULE_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_CONTENT_MODULE_BY_ID:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
