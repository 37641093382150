import 'isomorphic-fetch'

import { pageEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'
import {
  ADD_NEW_PAGE,
  FETCH_PAGE_CONTENTS,
  PAGE_UPDATE_STATUS,
  STORE_PAGE_TO_EDIT,
  REMOVE_PAGE_BY_ID
} from '../actionTypes'

export const resetPageActionStatus = () => async dispatch => {
  dispatch({
    type: ADD_NEW_PAGE,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}

export const resetUpdatePageStatus = () => async dispatch => {
  dispatch({
    type: PAGE_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}

export const fetchPagesList = params => async dispatch => {
  const { method, url } = pageEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { pages: rows, pagesCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_PAGE_CONTENTS,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const addNewPage = (page, accessToken) => async dispatch => {
  try {
    const { method, url } = pageEndpoint.add
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify({ page }),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { addedPage: { _id: newPageId }, message, success } = response
        dispatch({
          type: ADD_NEW_PAGE,
          payload: { newPageId, message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const fetchPageById = pageObjectId => async dispatch => {
  try {
    const { method, url } = pageEndpoint.edit(pageObjectId)
    const idToken = getIdToken()
    const apiResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .catch(error => console.error(error))
    dispatch({
      type: STORE_PAGE_TO_EDIT,
      payload: {
        ...apiResponse
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const updatePage = (pageObjectId, page, accessToken) => async dispatch => {
  try {
    const { method, url } = pageEndpoint.update(pageObjectId)
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify(page),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { message, success } = response
        dispatch({
          type: PAGE_UPDATE_STATUS,
          payload: { message, success }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: PAGE_UPDATE_STATUS,
          payload: { message: error.message, success: false }
        })
      })
  } catch (error) {
    console.log(error)
  }
}

export const removePageById = (pageObjectId, accessToken) => async dispatch => {
  const { method, url } = pageEndpoint.delete(pageObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    }
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message: deleteMessage, success: deleteStatus } = apiResponse

  dispatch({
    type: REMOVE_PAGE_BY_ID,
    payload: {
      deleteMessage,
      deleteStatus
    }
  })
}
