import { removeHTMLTags } from '~utils'

export const VALIDATION_ERRORS = {
  INVALID_BUTTON_TEXT: 'Provide valid submit button text',
  INVALID_DESCRIPTION: 'Provide valid description.',
  INVALID_DISCLAIMER: 'Provide valid disclaimer.',
  INVALID_FORM_ID: 'Provide valid form id',
  INVALID_HEADER_TEXT: 'Provide valid header text',
  INVALID_ICON_URL: 'Provide valid image url.',
  INVALID_SUCCESS_MESSAGE: 'Provide valid success message',
  INVALID_SUCCESS_TITLE: 'Provide valid success title',
  INVALID_SUCCESS_BUTTON: 'Provide valid success button text',
  HEADER_MAX_CHAR_LIMIT: 'Header exceeds the max limit 70 characters',
  DESC_MAX_CHAR_LIMIT: 'Description exceeds the max limit 155 characters',
  TITLE_MAX_CHAR_LIMIT: 'Title exceeds the max limit 70 characters',
  MESSAGE_MAX_CHAR_LIMIT: 'Header exceeds the max limit 155 characters',
  INVALID_EXPIRY_DATE: 'Provide valid Expiry date',
  INVALID_VENUE_ID: 'Provide valid venue name'
}

const isValidInput = text => text.length > 0

export const validateMaxHeaderCharLimit = (text) => removeHTMLTags(text).length <= 70

export const validateMaxDescriptionCharLimit = (text) => removeHTMLTags(text).length <= 155

export const validateMaxTitleCharLimit = (text) => removeHTMLTags(text).length <= 70

export const validateMaxMessageCharLimit = (text) => removeHTMLTags(text).length <= 155

export const validateOptInModal = sponsor => {
  const { formstack_id: formId, header, button_text: buttonText, description, venue_id: venueId, success_screen: { title, message }, count_down: { status, expiration_date_time: expiryDate } } = sponsor

  if (!isValidInput(venueId)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_VENUE_ID }
  }

  if (!isValidInput(formId)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_FORM_ID }
  }

  if (!isValidInput(header)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_HEADER_TEXT }
  }

  if (!isValidInput(buttonText)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_BUTTON_TEXT }
  }

  if (!isValidInput(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DESCRIPTION }
  }

  if (!isValidInput(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  if (!isValidInput(message)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_SUCCESS_MESSAGE }
  }

  if (!validateMaxTitleCharLimit(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.TITLE_MAX_CHAR_LIMIT }
  }

  if (!validateMaxDescriptionCharLimit(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.DESC_MAX_CHAR_LIMIT }
  }

  if (!validateMaxHeaderCharLimit(header)) {
    return { isValid: false, message: VALIDATION_ERRORS.HEADER_MAX_CHAR_LIMIT }
  }

  if (!validateMaxMessageCharLimit(message)) {
    return { isValid: false, message: VALIDATION_ERRORS.MESSAGE_MAX_CHAR_LIMIT }
  }

  if (status && !expiryDate) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_EXPIRY_DATE }
  }

  return { isValid: true, message: '' }
}

export const validateForm = form => {
  const { formstack_id: formId = '', header = '', submit_button_text: buttonText = '', description = '', disclaimer = '', success_screen: { title = '', message, button_text: successBtnText = '' } } = form

  if (!isValidInput(formId)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_FORM_ID }
  }

  if (!isValidInput(buttonText)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_BUTTON_TEXT }
  }

  if (!isValidInput(header)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_HEADER_TEXT }
  }

  if (!isValidInput(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DESCRIPTION }
  }

  if (!isValidInput(disclaimer)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DISCLAIMER }
  }

  if (!isValidInput(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_SUCCESS_TITLE }
  }

  if (!isValidInput(message)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_SUCCESS_MESSAGE }
  }

  if (!isValidInput(successBtnText)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_SUCCESS_BUTTON }
  }

  if (!validateMaxTitleCharLimit(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.TITLE_MAX_CHAR_LIMIT }
  }

  if (!validateMaxDescriptionCharLimit(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.DESC_MAX_CHAR_LIMIT }
  }

  if (!validateMaxHeaderCharLimit(header)) {
    return { isValid: false, message: VALIDATION_ERRORS.HEADER_MAX_CHAR_LIMIT }
  }

  if (!validateMaxMessageCharLimit(message)) {
    return { isValid: false, message: VALIDATION_ERRORS.MESSAGE_MAX_CHAR_LIMIT }
  }

  return { isValid: true, message: '' }
}
