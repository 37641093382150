import React, { useState } from 'react'
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons'
import { Image, Space, Spin, Upload, notification } from 'antd'
import PropTypes from 'prop-types'
import { fetchPresignedUrl, uploadFileToS3WithPresignedUrl } from '~actions'

const { Dragger } = Upload

const LogoUploader = ({
  handleUpload,
  logoUrl
}) => {
  const [uploadStatus, setUploadStatus] = useState(false)
  const [fileList, setFileList] = useState([])

  const handleImageDelete = (event) => {
    handleUpload('')
    setFileList([])
    event.preventDefault()
  }

  const handleChange = async ({ file }) => {
    if (fileList.length > 1) {
      notification.warning({ message: 'You can only upload one file!' })
      return
    }
    const isImage = file.type.startsWith('image/')
    const isSVG = file.type === 'image/svg+xml'
    if (!isImage && !isSVG) {
      notification.warning({ message: 'You can only upload image files (JPG, PNG, JPEG, SVG)' })
    }
    try {
      setUploadStatus(true)
      const fileName = file?.name.split('.')
      const formattedFile = `${fileName[0]}_${new Date().getTime()}.${fileName[1]}`
      const response = await fetchPresignedUrl({
        fileName: `event-engine/${formattedFile}`,
        fileType: file.type
      })
      const { presignedPutUrl, url } = response
      await uploadFileToS3WithPresignedUrl(presignedPutUrl, file)
      setUploadStatus(false)
      handleUpload(url)
      setFileList([file])
    } catch (error) {
      console.error(error)
      setUploadStatus(false)
    }
  }

  return (<Space direction='vertical' size={12}>{uploadStatus && <Spin indicator={<LoadingOutlined spin />} size="large" />}
    { logoUrl &&
      <>
        <Image
          src={logoUrl}
          style={{ maxHeight: 'unset', height: 'auto' }}
        />
        <Space style={{ justifyContent: 'space-between', display: 'flex' }}>
          <a href={logoUrl} rel="noreferrer" style={{ fontSize: 12 }} target='_blank' >Link</a>
          <a className="cloudinary-delete" onClick={handleImageDelete} style={{ color: '#ff4d4f', fontSize: 12 }} type='link'> Delete </a>
        </Space>
      </>
    }
    {!logoUrl && <Dragger
      accept='image/*,image/svg+xml'
      beforeUpload={() => false}
      fileList={fileList}
      maxCount={1}
      onChange={handleChange}
      onRemove={() => setFileList([])}
    >
      <p className='ant-upload-drag-icon'>
        <InboxOutlined />
      </p>
      <p className='ant-upload-text' style={{ fontSize: 12 }}>Click or drag file to this area to upload</p>
    </Dragger>}
  </Space>
  )
}

LogoUploader.propTypes = {
  handleUpload: PropTypes.func,
  logoUrl: PropTypes.string
}

export default LogoUploader
