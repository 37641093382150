import React from 'react'
import { Button, notification } from 'antd'

import { Route } from 'react-router-dom'
import { ItemList } from '~components'
import { getCurrentUser } from '~context'

import { PageLayout } from '~stories'

import styles from './PagesList.module.css'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const getEventNameWithLink = page => <a href={`/pages/${page._id}/edit`}>{String(page.name).toUpperCase()}</a>

const columnData = [
  {
    sorter: true,
    title: 'Title',
    dataIndex: 'name',
    render: (text, row) => getEventNameWithLink(row),
    width: '25%',
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    dataIndex: 'slug',
    title: 'Slug',
    width: '30%'
  },
  {
    dataIndex: 'type',
    title: 'Type',
    width: '30%'
  }
]

const addNewPage = isContentEditor => {
  return (
    <Route render={({ history }) => (
      <Button
        key="1"
        onClick={() => {
          isContentEditor
            ? history.push('/pages/new')
            : notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        }}
        type="primary"
      >
        Add Page
      </Button>
    )} />
  )
}

const PagesList = () => {
  const { userDetails: { isContentEditor } } = getCurrentUser()

  return (
    <PageLayout extra={[
      addNewPage(isContentEditor)
    ]} title="Pages">
      <div className={styles.eventsLayoutBackground}>
        <ItemList
          columns={columnData}
          resource='pages'
        />
      </div>
    </PageLayout>
  )
}

export default PagesList
