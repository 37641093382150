const VALIDATION_ERRORS = {
  INVALID_DESCRIPTION: 'Provide valid hero description.',
  INVALID_SUBTITLE: 'Provide valid hero sub_title.',
  INVALID_ICON_URL: 'Provide valid hero icon url.',
  INVALID_BUTTON_NAME: 'Provide valid hero button label.',
  INVALID_PHONE_NUMBER: 'Provide valid hero phone number.',
  INVALID_EMAIL: 'Provide valid hero email.',
  INVALID_IMAGE: 'Provide valid hero image.',
  INVALID_CHAT_BUTTON: 'Provide valid hero chat button.',
  INVALID_FORM: 'Provide valid hero form id.',
  INVALID_TITLE: 'Provide valid hero title',
  INVALID_EMAIL_FORMAT: 'Provide valid hero email address'
}

const isValidTitle = title => title.length > 0

const isValidImageObject = (image) => {
  if (!image || typeof image !== 'object') {
    return false
  }

  if (!image.src || typeof image.src !== 'string' || !image.src.trim()) {
    return false
  }

  if (!image.alt || typeof image.alt !== 'string') {
    return false
  }

  return true
}

const validateEmail = (email) => {
  const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegEx.test(email)
}

export const validateHero = flag => {
  const { title = '', sub_title: subTitle = '', description = '', button_name: buttonName = '', phone_number: phoneNumber = '', chat_button: chatButton = '', email = '', image } = flag

  if (!isValidTitle(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  if (!isValidTitle(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DESCRIPTION }
  }

  if (!isValidTitle(subTitle)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_SUBTITLE }
  }

  if (!isValidTitle(email)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_EMAIL }
  }

  if (!validateEmail(email)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_EMAIL_FORMAT }
  }

  if (!isValidTitle(buttonName)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_BUTTON_NAME }
  }

  if (!isValidTitle(phoneNumber)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_PHONE_NUMBER }
  }

  if (!isValidTitle(chatButton)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_CHAT_BUTTON }
  }

  if (!isValidImageObject(image)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_IMAGE }
  }

  return { isValid: true, message: '' }
}
