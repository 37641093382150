import React, { useEffect, useState } from 'react'
import { Button, Card, Typography, Modal, notification } from 'antd'
import idx from 'idx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { fetchEventById, removeEventById, resetEventActionStatus, updateEventById } from '~actions'
import { EventBasicInfo, EventDataFields, EventDefaultImage, EventGeneralInfo, EventPromotions, EventVenueInfo, RecommendationsSelector, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { history } from '~history'
import { Checkbox, MultiSelectDropdown, PageLayout, Spinner, Switch } from '~stories'
import { getEventPulldownValue, getUTCTime } from '~utils'
import { validateDataFields, validateEventAttributes } from '~validators'

import styles from './EditEvent.module.css'

const END_TIME_OF_DAY = '23:59:59'
const ERROR_MESSAGES = {
  DATA_FIELD_ERR: 'Missing required attributes in data fields',
  PUBLISH_WARNING: 'Changes to publish status will not be saved until you click update.',
  SPOTIFY_URL_ERR: 'Please provide valid URL for Spotify playlist.'
}
const EVENT_STATUS = {
  POSTPONED: 'postponed',
  ACTIVE: 'active'
}
const EVENT_STATUS_TEXT = {
  POSTPONED: 'Postponed'
}
const FORMATTER = {
  DATE: 'MM/DD/YYYY',
  TIME: 'HH:mm:ss'
}
const defaultGeneralInfo = {
  general_admission: '',
  cellphone_policy: '',
  special_offers: '',
  for_email: ''
}
const defaultEntranceInfo = { notes: '', scenario: '' }
const MAX_PULL_DOWN_DAYS_FOR_POSTPONED_EVENT = 180
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = 'Update Event'
const TBA = 'TBA'

const { Text } = Typography

const EventsEdit = ({ deleteEvent, deleteMessage, deleteStatus, event, fetchEvent, resetEventActionStatus, route, updateEventById, updateMessage, updateStatus }) => {
  const [allArtists, setAllArtists] = useState([])
  const [allEvents, setAllEvents] = useState([])
  const [allMSGFlags, setAllMSGFlags] = useState([])
  const [allRoxFlags, setAllRoxFlags] = useState([])
  const [allRoxGroupFlags, setAllRoxGroupFlags] = useState([])
  const [allSponsors, setAllSponsors] = useState([])
  const [dataFieldErrors, setDataFieldErrors] = useState({})
  const [existingEvent, setExistingEvent] = useState(null)
  const [isDataDeleted, setIsDataDeleted] = useState(true)
  const [isDataLoaded, setIsDataLoaded] = useState(true)
  const [isDataUpdated, setIsDataUpdated] = useState(true)
  const [promotedArtists, setPromotedArtists] = useState([])
  const [promotedEvents, setPromotedEvents] = useState([])
  const [promotedSponsors, setPromotedSponsors] = useState([])
  const [publish, setPublish] = useState()
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedFlags, setSelectedFlags] = useState([])
  const [selectedRoxFlags, setSelectedRoxFlags] = useState([])
  const [selectedRoxGroupFlags, setSelectedRoxGroupFlags] = useState([])
  const [allSubCategories, setAllSubCategories] = useState([])
  const [selectedSubCategories, setSelectedSubCategories] = useState([])
  const [selectedGenres, setSelectedGenres] = useState([])
  const [selectedVenue, setSelectedVenue] = useState([])
  const [showDefaultImage, setShowDefaultImage] = useState(false)
  const [showEventDeleteModal, setShowEventDeleteModal] = useState(false)
  const [allEventTypes, setAllEventTypes] = useState([])
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    const tmEventId = idx(route, _ => _.match.params.id)
    if (tmEventId) {
      setIsDataLoaded(false)
      fetchEvent(tmEventId)
    }
  }, [route, fetchEvent])

  useEffect(() => {
    if (event && event.id) {
      const selectedCategories = event.category.map(({ name }) => name) || []
      const selectedFlags = event?.flags ? event?.flags.map(({ title }) => title) : []
      const selectedRoxFlags = event?.roxFlags ? event?.roxFlags.map(({ title }) => title) : []
      const selectedRoxGroupFlags = event?.roxGroupFlags ? event?.roxGroupFlags.map(({ title }) => title) : []
      const allSubCategories = event?.allCategories.map(eventCategory => eventCategory.sub_category).flat()
      const selectedSubCategories = event.subCategory.map(({ name }) => name) || []
      const selectedGenres = event.genres.map(({ name }) => name) || []
      const [selectedVenue] = event.venue || []
      const allEventTypeProps = event.suitesEventType || []
      const { msgFlagsList = [], roxFlagsList = [], roxGroupsFlagsList = [] } = event
      setAllArtists(event.allArtists)
      setAllMSGFlags(msgFlagsList)
      setAllRoxFlags(roxFlagsList)
      setAllRoxGroupFlags(roxGroupsFlagsList)
      setAllEvents(event.allUpsellEvents)
      setAllSponsors(event.allSponsors)
      setAllEventTypes(allEventTypeProps)
      setExistingEvent({
        ...event,
        dbPublishStatus: event.publish,
        dbPostponedStatus: event.postponed
      })
      setIsDataLoaded(true)
      setPromotedArtists(event.artists)
      setPromotedEvents(event.relatedEvents)
      setPromotedSponsors(event.sponsors)
      setPublish(event.publish)
      setSelectedCategories(selectedCategories)
      setSelectedFlags(selectedFlags)
      setSelectedRoxFlags(selectedRoxFlags)
      setSelectedRoxGroupFlags(selectedRoxGroupFlags)
      setSelectedSubCategories(selectedSubCategories)
      setAllSubCategories(allSubCategories)
      setSelectedGenres(selectedGenres)
      setSelectedVenue(selectedVenue)
    }
  }, [event])

  useEffect(() => {
    if (updateStatus && updateMessage) {
      setIsDataLoaded(false)
      setIsDataUpdated(false)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(false)
      setIsDataUpdated(true)
      notification.error({ message: updateMessage })
      resetEventActionStatus()
    }
  }, [updateStatus, updateMessage, resetEventActionStatus])

  useEffect(() => {
    if (deleteStatus && deleteMessage) {
      setIsDataLoaded(false)
      notification.success({ message: deleteMessage })
      setTimeout(() => {
        history.push('/events')
      }, 1500)
    } else if (!deleteStatus && deleteMessage) {
      setIsDataLoaded(false)
      setIsDataDeleted(true)
      notification.error({ message: deleteMessage })
      resetEventActionStatus()
    }
  }, [deleteStatus, deleteMessage, resetEventActionStatus])

  const updateTags = (tags) => {
    const updatedEvent = { ...existingEvent, tags }
    setExistingEvent(updatedEvent)
  }

  const updateSocialTags = (socialTags) => {
    const updatedEvent = { ...existingEvent, socialTags }
    setExistingEvent(updatedEvent)
  }

  const onHeadlinerClick = selectedItem => {
    const artists = [...promotedArtists]
    const updatedArtists = artists.map(artist => (artist.id === selectedItem.id ? { ...selectedItem } : artist))
    setPromotedArtists(updatedArtists)
  }

  const handleDefaultImageModalCancel = () => {
    setShowDefaultImage(false)
  }

  const handleDeleteEventModalCancel = () => {
    setShowEventDeleteModal(false)
  }

  const handleDeleteEventAction = () => {
    const { eventObjectId } = existingEvent
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    setIsDataDeleted(false)
    setIsDataLoaded(false)
    deleteEvent(eventObjectId, accessToken)
    setShowEventDeleteModal(false)
  }

  const setDontOverrideRecommendations = event => {
    const status = event.target.checked
    const updatedEvent = { ...existingEvent, doNotOverrideRecommendations: status }
    setExistingEvent(updatedEvent)
  }

  const checkValidationErrorsInDataField = (dataFields) => {
    const dataFieldErrors = validateDataFields(dataFields)
    return dataFieldErrors
  }

  const getDateTime = (startDate, startTime, timezone) => {
    let dateTime = ''
    const formattedStartDate = startDate.split('T')[0]
    if (startTime && startTime !== TBA) {
      dateTime = getUTCTime(formattedStartDate, startTime, timezone)
    } else {
      dateTime = getUTCTime(formattedStartDate, END_TIME_OF_DAY, timezone)
    }
    return dateTime
  }

  const handleSubmit = () => {
    const { dataFields, entranceInfo, genres, id, name, postponed, postponedText, spotifyPlaylistUrl, startDate, startTime, statusText, subTitle, timezone, series, category, flags = [] } = existingEvent
    const dataFieldErrors = checkValidationErrorsInDataField(dataFields)
    const message = validateEventAttributes(entranceInfo, genres, name, postponed, postponedText, spotifyPlaylistUrl, statusText, subTitle, category, flags)
    setDataFieldErrors(dataFieldErrors)

    if (message) {
      notification.error({ message })
      return
    }
    const invalidDataFields = Object.keys(dataFieldErrors).filter(dataFieldError => !dataFieldErrors[dataFieldError].isValid)

    if (invalidDataFields && Object.keys(invalidDataFields).length > 0) {
      notification.error({ message: ERROR_MESSAGES.DATA_FIELD_ERR })
      return
    }

    const dateTime = getDateTime(startDate, startTime, timezone)
    const relatedEvents = promotedEvents.map(({ id }) => id)
    const sponsors = promotedSponsors.map(({ _id }) => _id)
    const [eventSeries] = series
    const artistsList = promotedArtists.map(({ id, title, is_headliner: isHeadLiner }) => ({
      id,
      name: title,
      is_headliner: isHeadLiner
    }))
    const selectedCategory = category.map(({ name, value }) => ({
      name,
      value
    }))

    const selectedFlag = existingEvent.flags.filter(flag => selectedFlags?.includes(flag.title))
    const selectedRoxFlag = allRoxFlags.filter(flag => selectedRoxFlags?.includes(flag.title))
    const selectedRoxGroupFlag = allRoxGroupFlags.filter(flag => selectedRoxGroupFlags?.includes(flag.title))

    const selectedSubCategoriesList = allSubCategories.filter(eventCategory => selectedSubCategories.includes(eventCategory.name))

    const eventObj = {
      ...existingEvent,
      artists: artistsList,
      category: selectedCategory,
      dateTime,
      flags: selectedFlag,
      roxFlags: selectedRoxFlag,
      roxGroupFlags: selectedRoxGroupFlag,
      relatedEvents,
      seriesId: eventSeries.id,
      sponsors,
      subCategory: selectedSubCategoriesList
    }

    if (!isContentEditor) {
      notification.error({ message: 'You are not authorized to perform this action.' })
      return
    }
    setIsDataLoaded(false)
    setIsDataUpdated(false)
    updateEventById(id, eventObj, accessToken)
  }

  const handlePostponedUpdate = postponed => {
    const { startDate, artists, startTime, timezone } = existingEvent
    const eventStartTime = (startTime === TBA) ? TBA : startTime
    const updatedStartDate = postponed ? moment.tz(startDate, timezone).add(MAX_PULL_DOWN_DAYS_FOR_POSTPONED_EVENT, 'days').format(FORMATTER.DATE) : startDate
    const pullDownFromSystem = getEventPulldownValue(updatedStartDate, eventStartTime, artists, timezone)
    const statusText = postponed ? EVENT_STATUS_TEXT.POSTPONED : ''
    const eventStatus = postponed ? EVENT_STATUS.POSTPONED : EVENT_STATUS.ACTIVE
    const updatedEvent = { ...existingEvent, postponed, pullDownFromSystem, statusText, status: eventStatus }
    setExistingEvent(updatedEvent)
  }

  const handleSubtitleUpdate = event => {
    const subTitle = event.target.value
    const updatedEvent = { ...existingEvent, subTitle }
    setExistingEvent(updatedEvent)
  }

  const handleNameUpdate = event => {
    const name = event.target.value
    const updatedEvent = { ...existingEvent, name }
    setExistingEvent(updatedEvent)
  }

  const handlePostponedTextUpdate = event => {
    const postponedText = event.target.value
    const updatedEvent = { ...existingEvent, postponedText }
    setExistingEvent(updatedEvent)
  }

  const handleStatusTextUpdate = event => {
    const statusText = event.target.value
    const updatedEvent = { ...existingEvent, statusText }
    setExistingEvent(updatedEvent)
  }

  const setPublishStatus = publish => {
    setPublish(publish)
    notification.warning({ message: ERROR_MESSAGES.PUBLISH_WARNING })
    const updatedEvent = { ...existingEvent, publish }
    setExistingEvent(updatedEvent)
  }

  const handleUseSponsorLogoForVenue = event => {
    const status = event.target.checked
    const updatedEvent = { ...existingEvent, useSponsorLogoForVenue: status }
    setExistingEvent(updatedEvent)
  }

  const handleSpanMultipleDaysUpdate = event => {
    const status = event.target.checked
    const updatedEvent = { ...existingEvent, spanMultipleDays: status }
    setExistingEvent(updatedEvent)
  }

  const handleSpotifyPlaylistUrlUpdate = (event) => {
    const spotifyPlaylistUrl = event.target.value
    const updatedEvent = { ...existingEvent, spotifyPlaylistUrl }
    setExistingEvent(updatedEvent)
  }

  const handleEventEndDateUpdate = date => {
    const endDate = date.startOf('day')
    const updatedEvent = { ...existingEvent, endDate }
    setExistingEvent(updatedEvent)
  }

  const handleEventPullDownTimeUpdate = date => {
    const pullDownFromSystem = date.toISOString()
    const updatedEvent = { ...existingEvent, pullDownFromSystem }
    setExistingEvent(updatedEvent)
  }

  const handleDoorOpenTimeUpdate = selectedDate => {
    const doorOpenTime = selectedDate ? selectedDate.format(FORMATTER.TIME) : TBA
    const updatedEvent = { ...existingEvent, doorOpenTime }
    setExistingEvent(updatedEvent)
  }

  const handleStartTimeUpdate = selectedDate => {
    const startTime = selectedDate ? selectedDate.format(FORMATTER.TIME) : TBA
    const updatedEvent = { ...existingEvent, startTime }
    setExistingEvent(updatedEvent)
  }

  const handleEntranceScenatioUpdate = selectedItem => {
    const entranceInfo = existingEvent.entranceInfo ?? defaultEntranceInfo
    entranceInfo.scenario = selectedItem
    const updatedEvent = { ...existingEvent, entranceInfo }
    setExistingEvent(updatedEvent)
  }

  const handleEntranceNotesUpdate = entranceNotes => {
    const entranceInfo = existingEvent.entranceInfo ?? defaultEntranceInfo
    entranceInfo.notes = entranceNotes || ''
    const updatedEvent = { ...existingEvent, entranceInfo }
    setExistingEvent(updatedEvent)
  }

  const handleDeleteEvent = () => {
    setShowEventDeleteModal(true)
  }

  const handleCellPhonePolicyUpdate = value => {
    const generalInfo = existingEvent.generalInfo ?? defaultGeneralInfo
    const updatedEvent = {
      ...existingEvent,
      generalInfo: {
        ...generalInfo,
        cellphone_policy: value
      }
    }
    setExistingEvent(updatedEvent)
  }

  const handleForEmailUpdate = value => {
    const generalInfo = existingEvent.generalInfo ?? defaultGeneralInfo
    const updatedEvent = {
      ...existingEvent,
      generalInfo: {
        ...generalInfo,
        for_email: value
      }
    }
    setExistingEvent(updatedEvent)
  }

  const handleGeneralAdmissionUpdate = value => {
    const generalInfo = existingEvent.generalInfo ?? defaultGeneralInfo
    const updatedEvent = {
      ...existingEvent,
      generalInfo: {
        ...generalInfo,
        general_admission: value
      }
    }
    setExistingEvent(updatedEvent)
  }

  const handleSpecialOffersUpdate = value => {
    const generalInfo = existingEvent.generalInfo ?? defaultGeneralInfo
    const updatedEvent = {
      ...existingEvent,
      generalInfo: {
        ...generalInfo,
        special_offers: value
      }
    }
    setExistingEvent(updatedEvent)
  }

  const genOverrideFlag = () => {
    return <div onClick={ e => e.stopPropagation() }>
      <Checkbox
        checked={existingEvent.doNotOverrideRecommendations}
        isInline
        label='DO NOT OVERRIDE RECOMMENDATIONS'
        onChange={setDontOverrideRecommendations}
      />
    </div>
  }

  const handleDoNotOverrideFromTMUpdate = event => {
    const status = event.target.checked
    const { premiumPartners } = existingEvent
    const [premiumPartner] = premiumPartners
    premiumPartner.do_not_override_from_tm = status
    const updatedEvent = {
      ...existingEvent,
      premiumPartners: [premiumPartner]
    }
    setExistingEvent(updatedEvent)
  }

  const handleEarlyOnSaleAvailabilityUpdate = event => {
    const date = new Date().toISOString()
    const { premiumPartners } = existingEvent
    const status = event.target.checked
    const [premiumPartner] = premiumPartners

    const earlyOnSale = {
      ...premiumPartner.earlyOnSale,
      is_available: status,
      start_date_time: status ? moment(date).toISOString() : null,
      end_date_time: status ? moment(date).add(1, 'day').toISOString() : null
    }

    premiumPartner.early_on_sale = {
      ...earlyOnSale
    }
    const updatedEvent = {
      ...existingEvent,
      premiumPartners: [premiumPartner]
    }
    setExistingEvent(updatedEvent)
  }

  const handlePreferredSeatingAvailabilityUpdate = event => {
    const { premiumPartners } = existingEvent
    const status = event.target.checked
    const [premiumPartner] = premiumPartners

    const preferredSeating = {
      ...premiumPartner.preferredSeating,
      is_available: status
    }
    premiumPartner.preferred_seating = {
      ...preferredSeating
    }
    const updatedEvent = {
      ...existingEvent,
      premiumPartners: [premiumPartner]
    }
    setExistingEvent(updatedEvent)
  }

  const handleEarlyOnSaleDateUpdate = dates => {
    const { premiumPartners } = existingEvent
    const [premiumPartner] = premiumPartners
    const { early_on_sale: earlyOnSale } = premiumPartner
    const { is_available: isEarlyOnSaleAvailable } = earlyOnSale
    const eosDates = dates || [null, null]
    const [startDate, endDate] = isEarlyOnSaleAvailable ? eosDates : [null, null]
    const startDateTime = startDate ? startDate.toISOString() : null
    const endDateTime = endDate ? endDate.toISOString() : null

    premiumPartner.early_on_sale = {
      ...premiumPartner.earlyOnSale,
      is_available: isEarlyOnSaleAvailable,
      start_date_time: startDateTime,
      end_date_time: endDateTime
    }

    const updatedEvent = {
      ...existingEvent,
      premiumPartners: [premiumPartner]
    }

    setExistingEvent(updatedEvent)
  }

  const handleEventLogoUpdate = selectedItem => {
    const [selectedVenue] = existingEvent.venue
    const venueLogos = selectedVenue.logos
    const eventLogo = venueLogos.find(logo => logo.name === selectedItem)
    const updatedEvent = { ...existingEvent, eventLogo }
    setExistingEvent(updatedEvent)
  }

  const handleCategoriesUpdate = selectedItems => {
    const category = selectedItems.map(name => ({ name }))
    setSelectedCategories(selectedItems)
    const categoryList = category.map(item => item.name)
    const selectedCategories = existingEvent.allCategories.filter(eventCategory => categoryList.includes(eventCategory.name))

    const updatedEvent = {
      ...existingEvent,
      category: [
        ...selectedCategories
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleSubCategoriesUpdate = selectedItems => {
    const subCategory = selectedItems.map(name => ({ name }))
    setSelectedSubCategories(selectedItems)

    const subCategoryList = subCategory.map(item => item.name)
    const selectedSubCategories = allSubCategories.filter(eventCategory => subCategoryList.includes(eventCategory.name))
    const updatedEvent = {
      ...existingEvent,
      sub_category: [
        ...selectedSubCategories
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleGenresUpdate = selectedItems => {
    const genres = selectedItems.map(name => ({ name }))
    const updatedEvent = { ...existingEvent, genres }
    setExistingEvent(updatedEvent)
    setSelectedGenres(selectedItems)
  }

  const handleEventTypeUpdate = selectedItems => {
    const eventType = selectedItems.map(type => type)
    const updatedEvent = { ...existingEvent, event_type: eventType }
    setExistingEvent(updatedEvent)
  }

  const handleFlagsUpdate = selectedItem => {
    setSelectedFlags(selectedItem)
    const selectedFlags = existingEvent.allFlags.filter(flag => [selectedItem].includes(flag.title))

    const updatedEvent = {
      ...existingEvent,
      flags: [
        ...selectedFlags
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleRoxFlagsUpdate = selectedItem => {
    setSelectedRoxFlags(selectedItem)
    const selectedFlags = allRoxFlags.filter(flag => [selectedItem].includes(flag.title))

    const updatedEvent = {
      ...existingEvent,
      roxFlags: [
        ...selectedFlags
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleRoxGroupFlagsUpdate = selectedItem => {
    setSelectedRoxGroupFlags(selectedItem)
    const selectedFlags = allRoxGroupFlags.filter(flag => [selectedItem].includes(flag.title))

    const updatedEvent = {
      ...existingEvent,
      roxGroupFlags: [
        ...selectedFlags
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleDataFieldsUpdate = (dataFields) => {
    const updatedEvent = { ...existingEvent, dataFields }
    setExistingEvent(updatedEvent)
  }

  const goToListEventsButton = (
    <Route key="listEvents" render={({ history }) => (
      <Button
        onClick={() => {
          resetEventActionStatus()
          history.push('/events')
        }}
        type="primary"
      >
        Go to Events List
      </Button>
    )} />
  )

  const editEventsButton = (
    <Route key="editEvent" render={() => (
      <Button
        onClick={() => {
          resetEventActionStatus()
          window.location.reload()
        }}
        type="primary"
      >
        Edit Event
      </Button>
    )} />
  )

  const publishSwitch = <Switch
    checked={publish}
    isInline={true}
    label="Publish"
    onChange={setPublishStatus}
  />

  const submitButton = <Button className={styles.updateActionButton} loading={!isDataUpdated} onClick={handleSubmit} type='primary'>
    Update
  </Button>

  const deleteButton = <Button className={styles.deleteActionButton} loading={!isDataDeleted} onClick={handleDeleteEvent} type='danger'>
  Delete
  </Button>

  return (
    <PageLayout extra={[publishSwitch, submitButton, deleteButton]} title={PAGE_TITLE} >
      {
        existingEvent && <div className={styles.eventsLayoutBackground}>
          <EventBasicInfo
            allEventTypes={allEventTypes}
            event={existingEvent}
            handleDoorOpenTimeUpdate={handleDoorOpenTimeUpdate}
            handleEntranceNotesUpdate={handleEntranceNotesUpdate}
            handleEntranceScenatioUpdate={handleEntranceScenatioUpdate}
            handleEventEndDateUpdate={handleEventEndDateUpdate}
            handleEventPullDownTimeUpdate={handleEventPullDownTimeUpdate}
            handleEventTypeUpdate={handleEventTypeUpdate}
            handleNameUpdate={handleNameUpdate}
            handlePostponedStatusUpdate={handlePostponedUpdate}
            handlePostponedTextUpdate={handlePostponedTextUpdate}
            handleSpanMultipleDaysUpdate={handleSpanMultipleDaysUpdate}
            handleSpotifyPlaylistUrlUpdate={handleSpotifyPlaylistUrlUpdate}
            handleStartTimeUpdate={handleStartTimeUpdate}
            handleStatusTextUpdate={handleStatusTextUpdate}
            handleSubtitleUpdate={handleSubtitleUpdate}
            handleUseSponsorLogoForVenueUpdate={handleUseSponsorLogoForVenue}
            updateSocialTags={updateSocialTags}
            updateTags={updateTags}
          />
        </div>
      }
      <br />
      <Card title="MSG Flag">
        {existingEvent && <MultiSelectDropdown
          allowClear={true}
          customStyle={{ width: 450 }}
          displayField="title"
          dropdownOptions={allMSGFlags}
          handleChange={handleFlagsUpdate}
          selectedItems={selectedFlags}
          valueField="title"
        />}
      </Card>
      <Card title="Rox Indy Flag">
        {existingEvent && <MultiSelectDropdown
          allowClear={true}
          customStyle={{ width: 450 }}
          displayField="title"
          dropdownOptions={allRoxFlags}
          handleChange={handleRoxFlagsUpdate}
          selectedItems={selectedRoxFlags}
          valueField="title"
        />}
      </Card>
      <br />
      <Card title="Rox Group Flag">
        {existingEvent && <MultiSelectDropdown
          allowClear={true}
          customStyle={{ width: 450 }}
          displayField="title"
          dropdownOptions={allRoxGroupFlags}
          handleChange={handleRoxGroupFlagsUpdate}
          selectedItems={selectedRoxGroupFlags}
          valueField="title"
        />}
      </Card>
      <br />
      {
        existingEvent && <EventDataFields dataFieldErrors={dataFieldErrors} dataFields={existingEvent.dataFields} handleDataFieldsUpdate={handleDataFieldsUpdate}/>
      }
      <br />
      {
        existingEvent && <EventGeneralInfo generalInfo={existingEvent.generalInfo ?? defaultGeneralInfo}
          handleCellPhonePolicyUpdate={handleCellPhonePolicyUpdate}
          handleForEmailUpdate={handleForEmailUpdate}
          handleGeneralAdmissionUpdate={handleGeneralAdmissionUpdate}
          handleSpecialOffersUpdate={handleSpecialOffersUpdate}
        />
      }
      <br />
      {
        existingEvent && <EventVenueInfo
          allVenues={existingEvent.allVenues}
          eventLogo={existingEvent.eventLogo}
          handleEventLogoUpdate={handleEventLogoUpdate}
          selectedVenue={selectedVenue}
        />
      }
      <br />
      <Card title="Categories">
        {existingEvent && <MultiSelectDropdown
          allowClear={true}
          customStyle={{ width: 450 }}
          displayField="name"
          dropdownOptions={existingEvent.allCategories}
          handleChange={handleCategoriesUpdate}
          mode='multiple'
          selectedItems={selectedCategories}
          valueField="name"
        />}
      </Card>
      <br />
      <><Card title="Sub Categories">
        {existingEvent && <MultiSelectDropdown
          allowClear={true}
          customStyle={{ width: 450 }}
          displayField="name"
          dropdownOptions={allSubCategories}
          handleChange={handleSubCategoriesUpdate}
          mode='multiple'
          selectedItems={selectedSubCategories}
          valueField="name"
        />}
      </Card>
      <br /></>
      <Card title="Genres">
        {existingEvent && <MultiSelectDropdown
          allowClear={true}
          customStyle={{ width: 450 }}
          displayField="name"
          dropdownOptions={existingEvent.allGenres}
          handleChange={handleGenresUpdate}
          mode='multiple'
          selectedItems={selectedGenres}
          valueField="name"
        />}
      </Card>
      <br />
      {existingEvent && <EventPromotions
        handleDoNotOverrideFromTMUpdate={handleDoNotOverrideFromTMUpdate}
        handleEarlyOnSaleAvailabilityUpdate={handleEarlyOnSaleAvailabilityUpdate}
        handleEarlyOnSaleDateUpdate={handleEarlyOnSaleDateUpdate}
        handlePreferredSeatingAvailabilityUpdate={handlePreferredSeatingAvailabilityUpdate}
        premiumPartners={existingEvent.premiumPartners}
      />}
      <br />
      {existingEvent && <EventDefaultImage
        defaultImage={existingEvent.defaultImage}
        setShowDefaultImage={setShowDefaultImage} />}
      <br />
      <Card title="Artists">
        {existingEvent && <RecommendationsSelector
          allEvents={allArtists}
          onHeadlinerClick={onHeadlinerClick}
          promotedEvents={promotedArtists}
          resourceName='Artists'
          setAllEvents={setAllArtists}
          setPromotedEvents={setPromotedArtists}
          showHeadlinerSelection={true}
        />}
      </Card>
      <br />
      <Card title="Sponsors">
        {
          event && <RecommendationsSelector
            allEvents={allSponsors}
            promotedEvents={promotedSponsors}
            resourceName='Sponsors'
            setAllEvents={setAllSponsors}
            setPromotedEvents={setPromotedSponsors}
          />
        }
      </Card>
      <br />
      {
        existingEvent && <Card extra={genOverrideFlag()} title="Promote Events" >
          <RecommendationsSelector
            allEvents={allEvents}
            promotedEvents={promotedEvents}
            setAllEvents={setAllEvents}
            setPromotedEvents={setPromotedEvents}
          />
        </Card>
      }
      <br />
      {existingEvent && showDefaultImage && <Modal
        footer={null}
        onCancel={handleDefaultImageModalCancel}
        title="Preview"
        visible={showDefaultImage}
      >
        <img alt='Default Url' src={existingEvent.defaultImage.url} width='100%'/>
      </Modal>}
      {existingEvent && showEventDeleteModal && <Modal
        onCancel={handleDeleteEventModalCancel}
        onOk={handleDeleteEventAction}
        title="Delete Event"
        visible={showEventDeleteModal}
      >
        <Text>
          This event will be removed from any recommended events and also not be included in the salesforce catalogue for recommendations. Are you sure you want to delete this Event?
        </Text>
      </Modal>}
      <Spinner isLoading={!isDataLoaded} />
      {
        updateStatus && updateMessage && <ResultModal
          actions={[
            goToListEventsButton,
            editEventsButton
          ]}
          handleCancel={() => {
            resetEventActionStatus()
            history.push('/events')
          }}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

EventsEdit.propTypes = {
  deleteEvent: PropTypes.func.isRequired,
  deleteMessage: PropTypes.string,
  deleteStatus: PropTypes.bool,
  event: PropTypes.object.isRequired,
  fetchEvent: PropTypes.func.isRequired,
  resetEventActionStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateEventById: PropTypes.func.isRequired,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

const mapStateToProps = ({ events }) => ({
  deleteMessage: events.deleteMessage,
  deleteStatus: events.deleteStatus,
  event: events,
  updateMessage: events.message,
  updateStatus: events.success
})

const mapDispatchToProps = {
  deleteEvent: removeEventById,
  fetchEvent: fetchEventById,
  resetEventActionStatus,
  updateEventById
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EventsEdit)
)
