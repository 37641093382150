const {
  REACT_APP_CLIENT_ID,
  REACT_APP_COGNITO_DOMAIN,
  REACT_APP_DOMAIN_NAME
} = process.env

export const COGNITO_LOGIN_URL = `${REACT_APP_COGNITO_DOMAIN}/login?client_id=${REACT_APP_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${REACT_APP_DOMAIN_NAME}/authorization`

export const EDIT_MODE = 'edit'
export const PRICE_CLASSES = ['A', 'A+', 'B', 'B+', 'C', 'D', 'Ultra', 'C+', 'D+']
export const PRICE_TEMPLATE = {
  DYNAMIC: 'dynamic',
  FIXED: 'fixed'
}
export const SECTIONS = ['Premier', 'Premium', 'Prime', 'Orch/1st Mezz', '2nd Mezz', '3rd Mezz', 'Side Orch']

export const FLAGS_COLOR_CODES = [{
  name: 'Teal',
  value: '#56CCC3'
}, {
  name: 'Yellow',
  value: '#FFF636'
}, {
  name: 'Red',
  value: '#DA0D50'
}, {
  name: 'Purple',
  value: '#BE44E9'
}]

export const FLAG_APPS = [{
  name: 'ROX',
  value: 'rox'
}, {
  name: 'MSG',
  value: 'msg'
}]

export const ROX_FLAG_SUB_TYPE = [{
  name: 'Indy',
  value: 'indy'
}, {
  name: 'Groups',
  value: 'group'
}]

export const FLAGS_TYPES = [{
  name: 'Manual',
  value: 'manual'
}, {
  name: 'Automated',
  value: 'automated'
}]

export const LINK_TARGETS = [{
  label: 'SELF',
  value: '_self'
}, {
  label: 'BLANK',
  value: '_blank'
}]

export const VENUES_LIST = [{
  label: 'Madison Square Garden',
  value: 'KovZpZA7AAEA'
}, {
  label: 'Radio City Music Hall',
  value: 'KovZpZAE7vdA'
}, {
  label: 'The Beacon Theatre',
  value: 'KovZpZAEAd6A'
}, {
  label: 'The Theater at Madison Square Garden',
  value: 'KovZpZA7kvlA'
}, {
  label: 'The Chicago Theatre',
  value: 'KovZpZA6AJ6A'
}]

export const SPHERE_UPLOAD_GENERIC_ERROR_MSG = 'Unexpected error when attempting to upload image(s).'
export const SPHERE_UPLOAD_ALLOWED_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png']
export const SPHERE_PATH_LOCATION = {
  local: 'sphere_hygraph_assets_preprod/assets',
  dev: 'sphere_hygraph_assets_preprod/assets',
  qa: 'sphere_hygraph_assets_preprod/assets',
  staging: 'sphere_hygraph_assets_staging/assets',
  production: 'hygraph_assets/assets'
}

export const PAGE_TYPES = [{
  label: 'Premium Hospitality',
  value: 'premium-hospitality'
}]
