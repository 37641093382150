import { validateCardModules } from './CardModuleFormValidator'
import { validateContentModules } from './ContentModuleFormValidator'
import { validateHero } from './HeroFormValidator'
import { validateForm } from './OptInModalFormValidator'
import { validatePageMeta } from './PageMetaFormValidator'

const isValidTitle = title => title.length > 0
const VALIDATION_ERRORS = {
  INVALID_DESCRIPTION: 'Provide valid description.',
  INVALID_SUBTITLE: 'Provide valid sub_title.',
  INVALID_ICON_URL: 'Provide valid icon url.',
  INVALID_PAGE_TYPE: 'Provide valid page type',
  INVALID_PAGE_CONTENTS: 'Provide valid content modules',
  INVALID_PAGE_HERO: 'Provide valid hero content',
  INVALID_PAGE_META: 'Provide valid page meta content',
  INVALID_PAGE_CARDS: 'Provide valid cards',
  INVALID_BUTTON_NAME: 'Provide valid button_name.',
  INVALID_PHONE_NUMBER: 'Provide valid phone number.',
  INVALID_EMAIL: 'Provide valid email.',
  INVALID_IMAGE: 'Provide valid image.',
  INVALID_CHAT_BUTTON: 'Provide valid chat button.',
  INVALID_FORM: 'Provide valid form id.',
  INVALID_TITLE: 'Provide valid name',
  INVALID_SLUG: 'Provide valid slug',
  INVALID_EMAIL_FORMAT: 'Provide valid email address'
}

export const validatePage = page => {
  const {
    cards = [],
    content_modules: contentModules = [],
    type,
    hero = {},
    name,
    page_meta: pageMeta = {},
    slug,
    form = {}
  } = page

  if (!isValidTitle(name)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  if (!isValidTitle(slug)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_SLUG }
  }

  if (!isValidTitle(type)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_PAGE_TYPE }
  }

  if (Object.keys(hero).length === 0) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_PAGE_HERO }
  }

  if (hero) {
    const response = validateHero(hero)
    if (response.isValid) {
      if (contentModules.length === 0) {
        return { isValid: false, message: VALIDATION_ERRORS.INVALID_PAGE_CONTENTS }
      }
      if (contentModules) {
        const contentResponse = validateContentModules(contentModules)
        if (contentResponse.isValid) {
          if (Object.keys(pageMeta).length === 0) {
            return { isValid: false, message: VALIDATION_ERRORS.INVALID_PAGE_META }
          }
          if (pageMeta) {
            const pageMetaResponse = validatePageMeta(pageMeta)
            if (pageMetaResponse.isValid) {
              if (form) {
                const formResponse = validateForm(form)
                if (formResponse.isValid) {
                  if (cards.length === 0) {
                    return { isValid: false, message: VALIDATION_ERRORS.INVALID_PAGE_CARDS }
                  }
                  if (cards) {
                    return validateCardModules(cards)
                  }
                } else {
                  return formResponse
                }
              }
            } else {
              return pageMetaResponse
            }
          }
        } else {
          return contentResponse
        }
      }
    } else {
      return response
    }
  }

  return { isValid: true, message: '' }
}
