import React from 'react'

import {
  CalendarOutlined,
  // ControlOutlined,
  CopyOutlined,
  DesktopOutlined,
  // DollarCircleOutlined,
  // EnvironmentOutlined,
  // FileImageOutlined,
  FlagOutlined,
  // HistoryOutlined,
  HomeOutlined,
  // IdcardOutlined,
  // KeyOutlined,
  MenuOutlined,
  StarOutlined,
  StopOutlined,
  TagsOutlined,
  FileTextOutlined,
  // UploadOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons'

export default [
  {
    icon: <HomeOutlined />,
    key: 'dashboard',
    label: 'Dashboard',
    path: '/dashboard'
  },
  {
    icon: <CalendarOutlined />,
    key: 'events',
    label: 'Events',
    path: '/events'
  },
  {
    icon: <CopyOutlined />,
    key: 'series',
    label: 'Series',
    path: '/series'
  },
  {
    icon: <FileTextOutlined />,
    key: 'pages',
    label: 'Pages',
    path: '/pages'
  },
  // {
  //   icon: <EnvironmentOutlined />,
  //   key: 'venues',
  //   label: 'Venues',
  //   path: '/venues'
  // },
  // {
  //   icon: <FileImageOutlined />,
  //   key: 'hero-modules',
  //   label: 'Hero Modules',
  //   path: '/hero-modules'
  // },
  // {
  //   icon: <ControlOutlined />,
  //   key: 'content-modules',
  //   label: 'Content Modules',
  //   path: '/content-modules'
  // },
  // {
  //   icon: <IdcardOutlined />,
  //   key: 'card-content',
  //   label: 'Cards',
  //   path: '/suite-cards'
  // },
  {
    icon: <FlagOutlined />,
    key: 'flags',
    label: 'Flags',
    path: '/flags'
  },
  {
    icon: <MenuOutlined />,
    key: 'navigations',
    label: 'Navigations (MSG)',
    path: '/navigations'
  },
  {
    icon: <DesktopOutlined />,
    key: 'opt-in-modal',
    label: 'Opt-in Modal',
    path: '/opt-in-modal'
  },
  // {
  //   icon: <DollarCircleOutlined />,
  //   key: 'sponsors',
  //   label: 'Sponsors',
  //   path: '/sponsors'
  // },
  // {
  //   icon: <KeyOutlined />,
  //   key: 'organizations',
  //   label: 'Organizations',
  //   path: '/organizations'
  // },
  {
    submenu: {
      icon: <TagsOutlined />,
      items: [{
        key: 'rockettes_promocodes',
        label: 'Rockettes',
        path: '/promo_codes/rockettes'
      },
      {
        key: 'msg_promocodes',
        label: 'MSG',
        path: '/promo_codes/msg'
      }
      ],
      key: 'promocodes',
      label: 'Promocodes'
    }
  },
  {
    submenu: {
      icon: <StarOutlined />,
      items: [
        {
          path: '/price_templates/fixed',
          key: 'fixed_price_templates',
          label: 'Fixed'
        },
        {
          path: '/price_templates/dynamic',
          key: 'dynamic_price_templates',
          label: 'Dynamic'
        }
      ],
      key: 'price_templates',
      label: 'Price Templates'
    }
  },
  // {
  //   icon: <UploadOutlined />,
  //   key: 'publishing_preferences',
  //   label: 'Publishing Preferences',
  //   path: '/publishing_preferences'
  // },
  // {
  //   icon: <HistoryOutlined />,
  //   key: 'tm_import_logs',
  //   label: 'TM Import Logs',
  //   path: '/tm_import_logs'
  // },
  {
    submenu: {
      icon: <StarOutlined />,
      items: [{
        key: 'tasks',
        label: 'Tasks',
        path: '/admin'
      },
      // {
      //   key: 'contents',
      //   label: 'Content Blocks',
      //   path: '/admin/content'
      // },
      {
        key: 'uploader',
        label: 'Sphere Uploader',
        path: '/admin/sphere/uploader'
      }
      ],
      key: 'admin',
      label: 'Admin'
    }
  },
  {
    icon: <UsergroupAddOutlined />,
    key: 'artists',
    label: 'Artists',
    path: '/artists'
  },
  {
    icon: <StopOutlined />,
    key: 'cf-invalidations',
    label: 'CF Invalidation',
    path: '/cf-invalidation'
  }
]
