import {
  ADD_NEW_SUITE_CARD_MODULE,
  SUITE_CARD_MODULE_UPDATE_STATUS,
  FETCH_SUITE_CARD_MODULES,
  REMOVE_SUITE_CARD_MODULE_BY_ID,
  STORE_SUITE_CARD_MODULE_TO_EDIT,
  NEW_SUITE_CARD_MODULE
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_SUITE_CARD_MODULE:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_SUITE_CARD_MODULES:
      return {
        ...state,
        ...action.payload
      }
    case STORE_SUITE_CARD_MODULE_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case SUITE_CARD_MODULE_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_SUITE_CARD_MODULE_BY_ID:
      return {
        ...state,
        ...action.payload
      }
    case NEW_SUITE_CARD_MODULE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
