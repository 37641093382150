const VALIDATION_ERRORS = {
  INVALID_SUBTITLE: 'Provide valid suite card subtitle.',
  INVALID_LABEL: 'Provide valid suite card label.',
  INVALID_ICON_URL: 'Provide valid suite card icon url.',
  INVALID_CTA: 'Provide valid suite card cta attributes.',
  INVALID_IMAGE: 'Provide valid suite card image',
  INVALID_TITLE: 'Provide valid suite card title',
  INVALID_TYPE: 'Provide valid suite card type',
  INVALID_CTAS: 'Provide valid suite card ctas'
}

const isValidTitle = title => title.length > 0

const isValidObject = (cta) => {
  if (!cta || typeof cta !== 'object') {
    return false
  }

  if (!cta.title || typeof cta.title !== 'string' || !cta.title.trim()) {
    return false
  }

  // if (!cta.href || typeof cta.href !== 'string') {
  //   return false
  // }

  return true
}

const isValidImageObject = (image) => {
  if (!image || typeof image !== 'object') {
    return false
  }

  if (!image.src || typeof image.src !== 'string' || !image.src.trim()) {
    return false
  }

  if (!image.alt || typeof image.alt !== 'string') {
    return false
  }

  return true
}

export const validateCardModule = content => {
  const { title = '', subtitle = '', type = '', image, ctas = [] } = content

  if (!isValidTitle(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  if (!isValidTitle(subtitle)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_SUBTITLE }
  }

  if (!isValidTitle(type)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TYPE }
  }

  if (!isValidImageObject(image)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_IMAGE }
  }

  if (ctas.length === 0) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_CTAS }
  }

  const validateResponse = ctas.map(cta => isValidObject(cta))
  const invalidObject = validateResponse.find(cardStatus => !cardStatus)
  if (invalidObject === false) {
    return { isValid: invalidObject, message: VALIDATION_ERRORS.INVALID_CTAS }
  }

  return { isValid: true, message: '' }
}

export const validateCardModules = cards => {
  const validateResponse = cards.map(card => validateCardModule(card))
  const invalidObject = validateResponse.find(card => !card.isValid)
  if (invalidObject) {
    return invalidObject
  }
  return { isValid: true, message: '' }
}
