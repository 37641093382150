/* eslint-disable camelcase */
import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Col, Row, Button, Card, Checkbox } from 'antd'
import PropTypes from 'prop-types'

import { LogoUploader, RecommendationsSelector } from '~components'
import { Label, Input, MultiSelectDropdown } from '~stories'
import CardCTA from './CardCTA'

const SuiteCardBasicInfo = ({
  allSuiteLevels,
  handleAddCta,
  handleAmenitiesChange,
  handleCapacityChange,
  handleCTAHrefChange,
  handleCTATitleChange,
  handleDeleteCta,
  handleEventIdeasChange,
  handleEventTypeChange,
  handleFormStatus,
  handleHospitalityChange,
  handleImageUrlUpdate,
  handleAllEventsChange,
  handleSelectedEventsChange,
  handleSizeChange,
  handleSubTitleChange,
  handleSuiteCardShortNameChange,
  handleSuiteCardTypeChange,
  handleTitleChange,
  suiteCard,
  suiteIndex,
  suitesAmenities,
  suitesCapacity,
  suitesEventIdeas,
  suitesEventType,
  suitesHospitality,
  suitesSize
}) => {
  const {
    suitesAllEvents = [],
    promotedEvents: availableEvents = [],
    ctas = [],
    filters = {},
    image,
    show_form: showForm,
    subtitle: subTitle,
    title
  } = suiteCard

  console.log(suiteCard)
  const suiteList = allSuiteLevels.map(suite => ({
    label: suite.title,
    value: suite.type
  }))

  return (
    <>
      <Input label='Title' maxLength={70} onChange={(event) => handleTitleChange(event, suiteIndex)} value={title} />
      <Input label='Sub Title' maxLength={155} onChange={(event) => handleSubTitleChange(event, suiteIndex)} value={subTitle}/>
      <Row gutter={16}>
        <Col span={12}>
          <MultiSelectDropdown
            allowClear={true}
            displayField="label"
            dropdownOptions={[{ label: 'Suite', value: 'suite' }, { label: 'Others', value: 'other' }]}
            handleChange={(event) => handleSuiteCardTypeChange(event, suiteIndex)}
            label={'Type'}
            selectedItems={suiteCard.type}
            valueField="value"
          />
        </Col>
        {suiteList && <Col span={12}>
          <MultiSelectDropdown
            allowClear={true}
            displayField="label"
            dropdownOptions={suiteList}
            handleChange={(event) => handleSuiteCardShortNameChange(event, suiteIndex)}
            label={'Short Name'}
            selectedItems={suiteCard.short_desc}
            valueField="value"
          />
        </Col>}
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          {suitesEventType?.options && <MultiSelectDropdown
            allowClear={true}
            displayField="label"
            dropdownOptions={suitesEventType?.options}
            handleChange={(event) => handleEventTypeChange(event, suiteIndex)}
            label={suitesEventType?.name}
            mode='multiple'
            selectedItems={filters.eventtype}
            valueField="value"
          />}
        </Col>
        <Col span={8}>
          {suitesHospitality?.options && <MultiSelectDropdown
            allowClear={true}
            displayField="label"
            dropdownOptions={suitesHospitality?.options}
            handleChange={(event) => handleHospitalityChange(event, suiteIndex)}
            label={suitesHospitality?.name}
            mode='multiple'
            selectedItems={filters.hospitalitytype}
            valueField="value"
          />}
        </Col>
        <Col span={8}>
          {suitesCapacity?.options && <MultiSelectDropdown
            allowClear={true}
            displayField="label"
            dropdownOptions={suitesCapacity?.options}
            handleChange={(event) => handleCapacityChange(event, suiteIndex)}
            label={suitesCapacity?.name}
            mode='multiple'
            selectedItems={filters?.capacity}
            valueField="value"
          />}
        </Col>
        <Col span={8}>
          {suitesAmenities?.options && <MultiSelectDropdown
            allowClear={true}
            displayField="label"
            dropdownOptions={suitesAmenities?.options}
            handleChange={(event) => handleAmenitiesChange(event, suiteIndex)}
            label={suitesAmenities?.name}
            mode='multiple'
            selectedItems={filters?.amenities}
            valueField="value"
          />}
        </Col>

        <Col span={8}>
          {suitesEventIdeas?.options && <MultiSelectDropdown
            allowClear={true}
            displayField="label"
            dropdownOptions={suitesEventIdeas?.options}
            handleChange={(event) => handleEventIdeasChange(event, suiteIndex)}
            label={suitesEventIdeas?.name}
            mode='multiple'
            selectedItems={filters.eventideas}
            valueField="value"
          />}
        </Col>
        <Col span={8}>
          <MultiSelectDropdown
            allowClear={true}
            displayField="label"
            dropdownOptions={suitesSize?.options}
            handleChange={(event) => handleSizeChange(event, suiteIndex)}
            label={suitesSize?.name}
            mode='multiple'
            selectedItems={filters?.size}
            valueField="value"
          />
        </Col>
      </Row>
      <Card
        extra={ctas && ctas.length > -1 && <Button icon={<PlusOutlined />} onClick={(event) => handleAddCta(event, suiteIndex)} shape="circle" />}
        style={{ width: '100%' }}
        title="CTA"
      >
        {ctas && <Row gutter={24}>
          <Col span={24} >
            <div>
              {ctas.map((cta, ctaIndex) => (
                <CardCTA
                  key={`Suite_${ctaIndex}`}
                  cardCTA={cta}
                  ctaIndex={ctaIndex}
                  handleCTAHrefUpdate={(event) => handleCTAHrefChange(event, suiteIndex, ctaIndex)}
                  handleCTATitleUpdate={(event) => handleCTATitleChange(event, suiteIndex, ctaIndex)}
                  handleDeleteCta={handleDeleteCta}
                  suiteCTAs={ctas}
                  suiteIndex={suiteIndex}
                />
              ))}
            </div>
          </Col>
        </Row>}
      </Card><br />
      <Card
        title="Events"
      >
        <div style={{ margin: '0 25px 25px 0' }}>
          <Checkbox checked={showForm} onChange={event => {
            handleFormStatus(event, suiteIndex)
          }}>Enable Formstack</Checkbox><br />
        </div>
        <div style={{
          pointerEvents: (showForm || ['isl', 'lsl', 'els', 'sls'].includes(suiteCard.short_desc)) ? 'none' : 'auto'
        }}>
          <RecommendationsSelector
            allEvents={ suitesAllEvents }
            displayField='title'
            promotedEvents={ availableEvents }
            resourceName='Events'
            setAllEvents={(events) => handleAllEventsChange(events, suiteIndex)}
            setPromotedEvents={(events) => handleSelectedEventsChange(events, suiteIndex)}
          />
        </div>

      </Card>
      <Row gutter={16}>
        <Col span={18} style={{ marginTop: 24 }}>
          <Label value='Image' />
          <div>
            <LogoUploader handleUpload={(thumbnailImageUrl, secureImageURL) => {
              const imageUrl = secureImageURL || thumbnailImageUrl
              handleImageUrlUpdate(imageUrl, suiteIndex)
            }} logoUrl={image?.src} />
          </div>
        </Col>
      </Row>
    </>
  )
}

SuiteCardBasicInfo.propTypes = {
  allSuiteLevels: PropTypes.array.isRequired,
  handleAddCta: PropTypes.func,
  handleAllEventsChange: PropTypes.func,
  handleAmenitiesChange: PropTypes.func.isRequired,
  handleCTAHrefChange: PropTypes.func,
  handleCTATitleChange: PropTypes.func,
  handleCTAUpdate: PropTypes.func,
  handleCapacityChange: PropTypes.func.isRequired,
  handleDeleteCta: PropTypes.func,
  handleEventIdeasChange: PropTypes.func.isRequired,
  handleEventTypeChange: PropTypes.func.isRequired,
  handleFormStatus: PropTypes.func.isRequired,
  handleHospitalityChange: PropTypes.func.isRequired,
  handleImageUrlUpdate: PropTypes.func,
  handleLogoUpload: PropTypes.func,
  handlePhoneNumberChange: PropTypes.func,
  handleSelectedEventsChange: PropTypes.func,
  handleShowIconChange: PropTypes.func,
  handleSizeChange: PropTypes.func.isRequired,
  handleSubTitleChange: PropTypes.func.isRequired,
  handleSuiteCardShortNameChange: PropTypes.func.isRequired,
  handleSuiteCardTypeChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func,
  suiteCard: PropTypes.object.isRequired,
  suiteIndex: PropTypes.number.isRequired,
  suitesAmenities: PropTypes.array.isRequired,
  suitesCapacity: PropTypes.array.isRequired,
  suitesEventIdeas: PropTypes.array.isRequired,
  suitesEventType: PropTypes.array.isRequired,
  suitesHospitality: PropTypes.array.isRequired,
  suitesSize: PropTypes.array.isRequired
}

SuiteCardBasicInfo.defaultProps = {
  suiteCard: {}
}

export default SuiteCardBasicInfo
