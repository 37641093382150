import React from 'react'
import { Col, Row, Card } from 'antd'
import PropTypes from 'prop-types'

import { LogoUploader } from '~components'
import { Label, RichTextInput, Input } from '~stories'

const ContentModuleBasicInfo = ({
  contentModule,
  handleContentChange,
  handleImageUrlUpdate,
  handleCTANameChange,
  handleCTAUrlChange,
  handleHeaderChange,
  contentIndex
}) => {
  const {
    description,
    cta,
    header,
    icon
  } = contentModule ?? {}
  const { name, url } = cta ?? {}
  return (
    <>
      <Row gutter={16}>
        <Card
          style={{ width: '100%' }}
        >
          <Input label='Header' maxLength={70} onChange={(event) => handleHeaderChange(event, contentIndex)} value={header} />
          <RichTextInput label='Description' onChange={(description) => handleContentChange(description, contentIndex)} value={description}/>
        </Card>
      </Row>
      <Row gutter={16}>
        <Card
          style={{ width: '100%' }}
          title="CTA"
        >
          <Col span={12}>
            <Input label='Label' onChange={(event) => handleCTANameChange(event, contentIndex)} value={name}/>
          </Col>
          <Col span={12}>
            <Input label='Url' onChange={(event) => handleCTAUrlChange(event, contentIndex)} value={url}/>
          </Col>
        </Card>
      </Row>
      <Row gutter={16}>
        <Card
          style={{ width: '100%' }}
        >
          <Col span={18}>
            <Label value='Icon' />
            <div>
              <LogoUploader handleUpload={(thumbnailImageUrl, secureImageURL) => {
                const imageUrl = secureImageURL || thumbnailImageUrl
                handleImageUrlUpdate(imageUrl, contentIndex)
              }} logoUrl={icon} />
            </div>
          </Col>
        </Card>
      </Row>
    </>
  )
}

ContentModuleBasicInfo.propTypes = {
  contentIndex: PropTypes.number.isRequired,
  contentModule: PropTypes.object.isRequired,
  handleCTANameChange: PropTypes.func.isRequired,
  handleCTAUrlChange: PropTypes.func.isRequired,
  handleContentChange: PropTypes.func.isRequired,
  handleHeaderChange: PropTypes.func.isRequired,
  handleImageUrlUpdate: PropTypes.func,
  handleLogoUpload: PropTypes.func
}

ContentModuleBasicInfo.defaultProps = {
  contentModule: {}
}

export default ContentModuleBasicInfo
